import FraudBetTable from "Common/Tables/FraudBetTable";
import { useEffect, useMemo, useState } from "react";
import { Container, Card, Row, Col, Form } from "react-bootstrap";
import { authServices } from "Utils/auth/services";
import Select from "react-select";
import { clearSearchPayload, generateOptions, updateSearchPayload } from "Utils/searchFilter";

interface FraudBetProps {
  fraudBetType: number;
  activeTab: string;
}

const FraudBetComponent = ({ fraudBetType, activeTab }: FraudBetProps) => {
  const [fraudBetsData, setFraudBetsData] = useState<FraudBets[]>([]);
  const [searchList, setSearchList] = useState<SportSearchList>();
  const [userName, setUserName] = useState("");
  const [domainId, setDomainId] = useState<string>("");
  const [searchPayload, setSearchPayload] = useState<SportsSearchPayloadData>(
    {}
  );
  const [limit, setLimit] = useState<number>(50);
  const [formValuesFilter, setFormValuesFilter] = useState<{
    [key: string]: string;
  }>({});
  const [page, setPage] = useState<number>(1);
  const [listTotal, setListTotal] = useState<ProfitLossTotal>({
    total: 0,
    page: 0,
  });

  const eventOptions = useMemo(
    () =>
      generateOptions(
        searchList?.events_m_f || [],
        "event_id",
        "event_name",
        "type"
      ),
    [searchList?.events_m_f]
  );
  const matchOptions = useMemo(
    () => generateOptions(searchList?.matches || [], "match_id", "match_name"),
    [searchList?.matches]
  );
  const seriesOptions = useMemo(
    () => generateOptions(searchList?.series || [], "series_id", "series_name"),
    [searchList?.series]
  );
  const sportsOptions = useMemo(
    () => generateOptions(searchList?.sports || [], "sport_id", "sport_name"),
    [searchList?.sports]
  );


  const getSportSearchList = async (filters: any = {}) => {
    const { response } = await authServices.getSportsSearchList({
      type: "openBets",
      ...filters,
    });
    if (response?.data && response?.data.length > 0) {
      setSearchList(response?.data[0]);
    }
  };

  const getFraudBets = async (filters: any = {}) => {
    const { response } = await authServices.getFraudBets({
      limit: limit,
      page: page,
      ...filters,
    });

    if (response) {
      setFraudBetsData(response?.data?.data || []);
      setListTotal({
        total: response?.data?.metadata[0]?.total
          ? response?.data?.metadata[0]?.total / (filters?.limit || limit)
          : 1,
        page: response?.data?.metadata[0]?.page || page,
      });
    }
  };

  useEffect(() => {
    getSportSearchList()
  }, [])

  const handlePageFilter = (page: number, limit: number) => {
    setPage(page);
    setLimit(limit);
    getFraudBets({
      page: page,
      limit: limit,
      search: {
        is_fraud_bet: fraudBetType,
      },
    });
  };


  const handleSearchFilter = (type: SearchPayloadType, id: string) => {
    const payload: SportsSearchPayloadData = updateSearchPayload(
      type,
      id,
      searchPayload
    );
    getSportSearchList({ search: payload });
    setSearchPayload(payload);
    setPage(1);
  };

  const handleClearSelection = (type: SearchPayloadType) => {
    const payload = clearSearchPayload(type, searchPayload);
    getSportSearchList({ search: payload });

    setSearchPayload(payload);
    setPage(1);
  };


  useEffect(() => {

  }, [activeTab]);

  useEffect(() => {
    if (activeTab === "FraudBets") {
      setPage(1);
      setLimit(50);
      const { selection_name, is_back, odds, stack, ip_address } = formValuesFilter;
      const potentialParams = {
        ...searchPayload,
        is_fraud_bet: fraudBetType,
        user_name: userName,
        domain_name: domainId,
        selection_name,
        is_back,
        odds,
        stack,
        ip_address,
      };
      const searchParams = Object.fromEntries(
        Object.entries(potentialParams).filter(([_, value]) => value !== undefined && value !== null && value !== "")
      );
      getFraudBets({ search: searchParams });
    }
  }, [searchPayload, fraudBetType, activeTab, formValuesFilter, userName, domainId]);
  return (
    <>
      <Card>
        <Card.Body>
          <Form>
            <Row className="gy-3 align-items-end">
              <Col lg={3}>
                <Form.Label>Search Sport Name</Form.Label>
                <Select
                  isClearable
                  options={sportsOptions}
                  value={
                    sportsOptions.find(
                      (option: any) =>
                        option.value === searchPayload?.sport_id
                    ) || null
                  }
                  className="customSelect"
                  onChange={(e: any) => {
                    if (e) {
                      handleSearchFilter("sport_id", e.value);
                    } else {
                      handleClearSelection("sport_id");
                    }
                  }}
                />
              </Col>
              <Col lg={3}>
                <Form.Label>Search Series Name</Form.Label>
                <Select
                  isClearable
                  options={seriesOptions}
                  value={
                    seriesOptions.find(
                      (option: any) =>
                        option.value === searchPayload?.series_id
                    ) || null
                  }
                  className="customSelect"
                  onChange={(e: any) => {
                    if (e) {
                      handleSearchFilter("series_id", e.value);
                    } else {
                      handleClearSelection("series_id");
                    }
                  }}
                />
              </Col>
              <Col lg={3}>
                <Form.Label>Search Match Name</Form.Label>
                <Select
                  isClearable
                  options={matchOptions}
                  value={
                    matchOptions.find(
                      (option: any) =>
                        option.value === searchPayload?.match_id
                    ) || null
                  }
                  className="customSelect"
                  onChange={(e: any) => {
                    if (e) {
                      handleSearchFilter("match_id", e.value);
                    } else {
                      handleClearSelection("match_id");
                    }
                  }}
                />
              </Col>
              <Col lg={3}>
                <Form.Label>Search Market Name</Form.Label>
                <Select
                  isClearable
                  options={eventOptions}
                  value={
                    eventOptions.find(
                      (option: any) =>
                        option.value === searchPayload?.fancy_id ||
                        option.value === searchPayload?.market_id
                    ) || null
                  }
                  className="customSelect"
                  onChange={(e: any) => {
                    if (e) {
                      handleSearchFilter(
                        e.type == "1" ? "market_id" : "fancy_id",
                        e.value
                      );
                    } else {
                      handleClearSelection("market_id");
                    }
                  }}
                />
              </Col>

            </Row>
          </Form>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <FraudBetTable
            data={fraudBetsData}
            page={page}
            limit={limit}
            listTotal={listTotal}
            handlePageFilter={handlePageFilter}
            formValuesFilter={formValuesFilter}
            setFormValuesFilter={setFormValuesFilter}
            setUserName={setUserName}
            userName={userName}
            domainId={domainId}
            setDomainId={setDomainId}
          />
        </Card.Body>
      </Card>

    </>
  );
};

export default FraudBetComponent;
