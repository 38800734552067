import { useState } from "react";
import { authServices } from "Utils/auth/services";
import emitter from "Utils/emitter";

const useUserBalance = (user_id: string | null, fullExp: boolean) => {
  const [balance, setBalance] = useState(0);
  const [exp, setExp] = useState<number>(0);

  const fetchBalance = async () => {
    if (!user_id) return;
    try {
      const { response } = await authServices.showBalance({
        userid: user_id,
        full_exposure: fullExp,
      });
      setBalance(response.data.balance);
      setExp(response.data.liability);

      emitter.emit("balanceUpdated", {
        balance: response.data.balance,
        liability: response.data.liability,
      });
    } catch (err) {
      console.error("Error fetching balance data:", err);
    }
  };

  return { balance, exp, fetchBalance };
};

export default useUserBalance;
