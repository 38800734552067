import { Badge, Button, Card, Col, Dropdown, Modal, Table } from "react-bootstrap";
import { useState } from "react";
import { settingServices } from "Utils/setting/services";
import moment from "moment";
import Loader from "Common/Loader";

type TransactionData = {
  statementType: string;
  user_count: number;
  total_amount: number;
};

const TransactionStatus = () => {
  const [depositData, setDepositData] = useState<TransactionData[]>([]);
  const [withdrawData, setWithdrawData] = useState<TransactionData[]>([]);
  const [isLoadingDeposit, setIsLoadingDeposit] = useState(false);
  const [isLoadingWithdraw, setIsLoadingWithdraw] = useState(false);
  const [selectName, setSelectName] = useState("Select");
  const [selectNameW, setSelectNameW] = useState("Select");
  const [showModal, setModal] = useState(false);
  const [listData, setListData] = useState<any>();
  const [nameModals, setNameModals] = useState("");
  const ToggleModal = (dataList: any, name:string) => {
      setModal(!showModal);
      setListData(dataList);
      setNameModals(name)
  }

  const fetchTransactionData = async (days: number, transactionType: "deposit" | "withdraw") => {
    try {
      if (transactionType === "deposit") setIsLoadingDeposit(true);
      else setIsLoadingWithdraw(true);

      const { response } = await settingServices.getTotalReport({
        from_date: moment().subtract(days, "days"),
        to_date: moment(),
        limit: 20,
        search: ["statementQuery"],
      });

      if (response) {
        let filteredData = response?.data?.transactional_data || [];
        if (transactionType === "deposit") {
          filteredData = filteredData.filter((item: any) => item?.statementType !== "WITHDRAW_REQUEST");
          setDepositData(filteredData);
        } else if (transactionType === "withdraw") {
          filteredData = filteredData.filter((item: any) => item?.statementType !== "DEPOSIT_REQUEST");
          setWithdrawData(filteredData);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      if (transactionType === "deposit") setIsLoadingDeposit(false);
      if (transactionType === "withdraw") setIsLoadingWithdraw(false);
    }
  };

  const handleDepositSelection = (days: number, name: string) => {
    fetchTransactionData(days, "deposit");
    setSelectName(name)
  };

  const handleWithdrawSelection = (days: number, name: string) => {
    fetchTransactionData(days, "withdraw");
    setSelectNameW(name)
  };
  
  const clickHandler = () => {
    setModal(!showModal)
}

  return (
    <>
      {/* Deposit Section */}
      <Col xxl={4}>
        <Card className="card-height-100">
          <Card.Header className="d-flex border-bottom pb-3">
            <h5 className="card-title mb-0 flex-grow-1">Deposit Status
              <Badge onClick={()=>ToggleModal(depositData, "Deposit Status")} className="cursor-pointer bg-body-secondary border border-primary text-primary ms-1">View All</Badge>
            </h5>
            <div className="flex-shrink-0">
              <Dropdown className="card-header-dropdown sortble-dropdown cursor-pointer">
                <Dropdown.Toggle as="a" className="text-reset arrow-none mb-0">
                  <span className="text-muted dropdown-title">{selectName}</span> <i className="mdi mdi-chevron-down ms-1"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-end">
                  <Dropdown.Item onClick={() => handleDepositSelection(1, "Today")}>Today</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleDepositSelection(7, "This Week")}>This Week</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleDepositSelection(30, "This Month")}>This Month</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Card.Header>
          <div className="card-body">
            <div className="table-responsive">
              <Table>
                <thead className="table-light">
                  <tr>
                    <th>Type</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {depositData?.length !== 0 ? (
                    depositData.map((item, index) => (
                      <tr key={index}>
                        <td>{item?.user_count}</td>
                        <td>{item?.total_amount}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={2} className="text-center">No Data Found!</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </Card>
        {isLoadingDeposit && <Loader />}
      </Col>

      {/* Withdraw Section */}
      <Col xxl={4}>
        <Card className="card-height-100">
          <Card.Header className="d-flex border-bottom pb-3">
            <h5 className="card-title mb-0 flex-grow-1">Withdraw Status
              <Badge  onClick={()=>ToggleModal(withdrawData, "Withdraw Status")} className="cursor-pointer bg-body-secondary border border-primary text-primary ms-1">View All</Badge>
            </h5>
            <div className="flex-shrink-0">
              <Dropdown className="card-header-dropdown sortble-dropdown cursor-pointer">
                <Dropdown.Toggle as="a" className="text-reset arrow-none mb-0">
                  <span className="text-muted dropdown-title">{selectNameW}</span> <i className="mdi mdi-chevron-down ms-1"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-end">
                  <Dropdown.Item onClick={() => handleWithdrawSelection(1, "Today")}>Today</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleWithdrawSelection(7, "This Week")}>This Week</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleWithdrawSelection(30, "This Month")}>This Month</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Card.Header>
          <div className="card-body">
            <div className="table-responsive">
              <Table>
                <thead className="table-light">
                  <tr>
                    <th>Type</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {withdrawData?.length !== 0 ? (
                    withdrawData.map((item, index) => (
                      <tr key={index}>
                        <td>{item?.user_count}</td>
                        <td>{item?.total_amount}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={2} className="text-center">No Data Found!</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </Card>
        {isLoadingWithdraw && <Loader />}
      </Col>
      <Modal show={showModal} onHide={clickHandler} className="zoomIn" scrollable>
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <span>{nameModals}</span>
          <Button variant="light btn-sm" onClick={clickHandler}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body className='fs-md'>
          <div className="table-resposive">
            <Table>
              <thead className="table-light">
              <tr>
                    <th>Type</th>
                    <th>Amount</th>
                  </tr>
              </thead>
              <tbody>
                {
                  listData?.map((item: any) => {
                    return (
                      <tr >
                        <td>{item?.user_count}</td>
                        <td>{item?.total_amount}</td>
                      </tr>
                    )
                  })
                }


              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TransactionStatus;
