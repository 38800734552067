import { useEffect, useState } from 'react';
import { Button, Modal, Row, Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ToggleSwitch from 'Common/ToggleSwitch';
import snackbarUtil from 'Utils/snackBarUtil';
import { BetLimits } from 'pages/MatchDetail';

interface MatchSettingProps {
    show: boolean;
    clickHandler: any;
    limits: BetLimits | undefined;
    id: string | undefined;
    getLimit: any,
    fancyShow: boolean
    name: string,
    shoeOdss: boolean,
    marketId: string,
    marketName: string
    limitValidation: any
}

const
    MatchSetting = ({ limitValidation, show, clickHandler, limits, id, getLimit, fancyShow, name, shoeOdss, marketId, marketName }: MatchSettingProps) => {
        const [formData, setFormData] = useState<any>({
            market_min_stack: "",
            market_max_stack: "",
            market_min_odds_rate: "",
            market_max_odds_rate: "",
            market_max_profit: "",
            market_advance_bet_stake: "",
            min_volume_limit: "",
            inplay_max_volume_stake_0_10: "",
            inplay_max_volume_stake_10_40: "",
            inplay_max_volume_stake_40: "",
            max_volume_stake_0_10: "",
            max_volume_stake_10_40: "",
            max_volume_stake_40: "",
            session_min_stack: "",
            session_max_stack: "",
            session_max_profit: "",
        });
        const [tooltipMsg, setTooltipMsg] = useState<{ [key: string]: string }>({});
        const [showTooltip, setShowTooltip] = useState<{ [key: string]: boolean }>({});
        const [errors, setErrors] = useState<{ [key: string]: string }>({});
        useEffect(() => {
            if (limits) {
                setFormData({
                    market_min_stack: limits.market_min_stack?.toString() || "",
                    market_max_stack: limits.market_max_stack?.toString() || "",
                    market_min_odds_rate: limits.market_min_odds_rate?.toString() || "",
                    market_max_odds_rate: limits.market_max_odds_rate?.toString() || "",
                    market_max_profit: limits.market_max_profit?.toString() || "",
                    market_advance_bet_stake: limits.market_advance_bet_stake?.toString() || "",
                    min_volume_limit: limits.min_volume_limit?.toString() || "0",
                    inplay_max_volume_stake_0_10: limits.inplay_max_volume_stake_0_10?.toString() || "",
                    inplay_max_volume_stake_10_40: limits.inplay_max_volume_stake_10_40?.toString() || "",
                    inplay_max_volume_stake_40: limits.inplay_max_volume_stake_40?.toString() || "",
                    max_volume_stake_0_10: limits.max_volume_stake_0_10?.toString() || "",
                    max_volume_stake_10_40: limits.max_volume_stake_10_40?.toString() || "",
                    max_volume_stake_40: limits.max_volume_stake_40?.toString() || "",
                    session_min_stack: limits.session_min_stack?.toString() || "",
                    session_max_stack: limits.session_max_stack?.toString() || "",
                    session_max_profit: limits.session_max_profit?.toString() || "",
                });
            }
        }, [limits]);
        const [advanceMarket, setAdvanceMarket] = useState(true);

        const handleInputChange = (e: any) => {
            const { name, value } = e.target;
            const updatedValue = value.trim() === "" ? "" : Number(value);
            setFormData({ ...formData, [name]: updatedValue });
            validateField(name, value);
            if (name === "session_min_stack" || name === "session_max_stack") {
                validateField("session_min_stack", formData.session_min_stack);
                validateField("session_max_stack", formData.session_max_stack);
            }

        };

        const validateField = (name: string, value: string) => {
            const numericValue = Number(value);
            let errorMessage = "";
            const newTooltipMsg = { ...tooltipMsg };
            const newShowTooltip = { ...showTooltip };

            const addTooltip = (message: string) => {
                newTooltipMsg[name] = message;
                newShowTooltip[name] = true;
            };
            if (name === "market_max_stack" && numericValue <= 1000000) {
                delete newTooltipMsg[name];
                newShowTooltip[name] = false;
            }
            if (name === "market_min_stack" && numericValue >= 1) {
                delete newTooltipMsg[name];
                newShowTooltip[name] = false;
            }
            if (name === "market_max_odds_rate" && numericValue <= 100) {
                delete newTooltipMsg[name];
                newShowTooltip[name] = false;
            }
            if (name === "market_min_odds_rate" && numericValue >= 0.49) {
                delete newTooltipMsg[name];
                newShowTooltip[name] = false;
            }
            if (name === "market_max_profit" && numericValue <= 10000000) {
                delete newTooltipMsg[name];
                newShowTooltip[name] = false;
            }
            if (name === "market_advance_bet_stake" && numericValue <= 500000) {
                delete newTooltipMsg[name];
                newShowTooltip[name] = false;
            }
            if (name === "session_max_stack" && numericValue <= 10000000) {
                delete newTooltipMsg[name];
                newShowTooltip[name] = false;
            }
            if (name === "session_max_profit" && numericValue <= 2500000) {
                delete newTooltipMsg[name];
                newShowTooltip[name] = false;
            }
            if (!value) {
                errorMessage = 'This field is required';
            } else if (name === "market_min_stack" && numericValue > Number(formData.market_max_stack)) {
                errorMessage = "Min value cannot be greater than max value";
            } else if (name === "market_max_stack" && numericValue < Number(formData.market_min_stack)) {
                errorMessage = "Max value cannot be less than min value";
            } else if (name === "market_min_odds_rate" && numericValue > Number(formData.market_max_odds_rate)) {
                errorMessage = "Min value cannot be greater than max value";
            } else if (name === "market_max_odds_rate" && numericValue < Number(formData.market_min_odds_rate)) {
                errorMessage = "Max value cannot be less than min value";
            } else if (name === "inplay_max_volume_stake_40" && numericValue < Number(formData.inplay_max_volume_stake_0_10)) {
                errorMessage = "Max value cannot be less than min value";
            } else if (name === "max_volume_stake_40" && numericValue < Number(formData.max_volume_stake_0_10)) {
                errorMessage = "Max value cannot be less than min value";
            } else if (name === "session_min_stack" && numericValue > Number(formData.session_max_stack)) {
                errorMessage = "Min value cannot be greater than max value";
            } else if (name === "session_max_stack" && numericValue < Number(formData.session_min_stack)) {
                errorMessage = "Max value cannot be less than min value";
            }

            const parentChecks = [
                {
                    condition: name === "market_min_stack" && numericValue >  Number(formData.market_max_stack),
                    tooltipMsg: `Min value cannot be greater than max value`
                },
                {
                    condition: name === "market_max_stack" && numericValue > 1000000,
                    tooltipMsg: `Market Max Stack value should not be greater than  1000000`
                },
                {
                    condition: name === "market_max_odds_rate" && numericValue > 100,
                    tooltipMsg: `Market Max Odds Rate value should not be greater than 100`
                },
                {
                    condition: name === "market_bookmaker_min_odds_rate" && Number(numericValue) < 2,
                    tooltipMsg: `Market Bookmaker Min Odds Rate should be between (1-100)`
                },
                {
                    condition: name === "market_bookmaker_max_odds_rate" && Number(numericValue) > 100,
                    tooltipMsg: "Market Bookmaker Max Odds Rate should not exceed 100"
                },
                {
                    condition: name === "market_max_profit" && numericValue > 10000000,
                    tooltipMsg: `Market Max Profit value should not be greater than 10000000`
                },
                {
                    condition: name === "market_advance_bet_stake" && numericValue > 500000,
                    tooltipMsg: `Market advance bet stake value should not be greater than 500000`
                },
                {
                    condition: name === "session_max_stack" && numericValue > 10000000,
                    tooltipMsg: `Session Max Stack value should not be greater than 10000000`
                },
                {
                    condition: name === "session_max_profit" && Number(numericValue) > 2500000,
                    tooltipMsg: "Match Bets Delay should not exceed 2500000"
                }
            ];

            parentChecks.forEach(({ condition, tooltipMsg }) => {
                if (condition) addTooltip(tooltipMsg);
            });

            setErrors((prevErrors) => {
                const newErrors = { ...prevErrors };
                if (errorMessage) {
                    newErrors[name] = errorMessage;
                } else {
                    delete newErrors[name];
                }
                return newErrors;
            });
        
            setTooltipMsg(newTooltipMsg);
            setShowTooltip(newShowTooltip);

        };

        const token = localStorage.getItem("token")
        const getUpdateLimits = async (data: any) => {
            let payload;
            if (marketId === "match_id") {
                payload = data;
            } else if (marketId === "fancy_id") {
                payload = {
                    session_min_stack: data.session_min_stack,
                    session_max_stack: data.session_max_stack,
                    session_max_profit: data.session_max_profit,
                };
            } else {
                payload = {
                    market_min_stack: data.market_min_stack,
                    market_max_stack: data.market_max_stack,
                    market_min_odds_rate: data.market_min_odds_rate,
                    market_max_odds_rate: data.market_max_odds_rate,
                    market_max_profit: data.market_max_profit,
                    market_advance_bet_stake: data.market_advance_bet_stake,
                    min_volume_limit: data.min_volume_limit,
                    inplay_max_volume_stake_0_10: data.inplay_max_volume_stake_0_10,
                    inplay_max_volume_stake_10_40: data.inplay_max_volume_stake_10_40,
                    inplay_max_volume_stake_40: data.inplay_max_volume_stake_40,
                    max_volume_stake_0_10: data.max_volume_stake_0_10,
                    max_volume_stake_10_40: data.max_volume_stake_10_40,
                    max_volume_stake_40: data.max_volume_stake_40,
                };
            }

            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASEURL}/event/updateLimites`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        [marketId]: id || "",
                        values: payload,
                    }),
                });

                const result = await response.json();

                if (response.ok && result.status) {
                    snackbarUtil.success(result.msg);
                    clickHandler();
                    getLimit();
                } else {
                    snackbarUtil.error(result.msg || "An error occurred");
                }
            } catch (error) {
                snackbarUtil.error("Network error. Please try again later.");
            }
        };


        const handleSave = () => {
            const fieldNames = Object.keys(formData);
            let hasErrors = false;
            fieldNames.forEach((field) => {
                if (marketId === "market_id" && field.includes("session_")) {
                    return;
                }
                if (marketId === "fancy_id" && !field.includes("session_")) {
                    return;
                }
                if (!formData[field] && field !== "min_volume_limit") {
                    validateField(field, formData[field]);
                    hasErrors = true;
                }

                if (tooltipMsg[field]) {
                    hasErrors = true;
                }
            });

            if (!hasErrors) {
                getUpdateLimits(formData);
            }
        };



        return (
            <Modal size="xl" show={show} onHide={clickHandler} className="zoomIn" scrollable>
                <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-3 pb-md-0">
                    <div>{marketName}</div>
                    <Button variant="light btn-sm" onClick={clickHandler}><i className="ri-close-line fs-xl align-middle"></i></Button>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row className="g-3">
                            {shoeOdss && <><h5 className='text-primary mb-0'>Market Setting</h5>
                                <OverlayTrigger
                                    show={!!showTooltip?.market_min_stack}
                                    placement="top"
                                    overlay={<Tooltip>{tooltipMsg.market_min_stack}</Tooltip>}
                                ><Col lg={3}>
                                        <div className="form-floating">
                                            <Form.Control
                                                name="market_min_stack"
                                                type="number"
                                                id="MinStakeAmount"
                                                placeholder="Min. Stake Amount"
                                                value={formData.market_min_stack}
                                                onChange={handleInputChange}
                                                isInvalid={!!errors.market_min_stack}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.market_min_stack}</Form.Control.Feedback>
                                            <Form.Label htmlFor="MinStakeAmount">Min. Stake Amount</Form.Label>
                                        </div>
                                    </Col>

                                </OverlayTrigger>
                                <OverlayTrigger
                                    show={!!showTooltip?.market_max_stack}
                                    placement="top"
                                    overlay={<Tooltip>{tooltipMsg.market_max_stack}</Tooltip>}
                                >
                                    <Col lg={3}>
                                        <div className="form-floating">
                                            <Form.Control
                                                name="market_max_stack"
                                                type="number"
                                                id="MaxStakeAmount"
                                                placeholder="Max. Stake Amount"
                                                value={formData.market_max_stack}
                                                onChange={handleInputChange}
                                                isInvalid={!!errors.market_max_stack}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.market_max_stack}</Form.Control.Feedback>
                                            <Form.Label htmlFor="MaxStakeAmount">Max. Stake Amount</Form.Label>
                                        </div>
                                    </Col>
                                </OverlayTrigger>

                                <OverlayTrigger
                                    show={!!showTooltip?.market_min_odds_rate}
                                    placement="top"
                                    overlay={<Tooltip>{tooltipMsg.market_min_odds_rate}</Tooltip>}
                                >
                                    <Col lg={3}>
                                        <div className="form-floating">
                                            <Form.Control
                                                name="market_min_odds_rate"
                                                type="number"
                                                id="MinOddsRate"
                                                placeholder="Min. Odds Rate"
                                                value={formData.market_min_odds_rate}
                                                onChange={handleInputChange}
                                                isInvalid={!!errors.market_min_odds_rate}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.market_min_odds_rate}</Form.Control.Feedback>
                                            <Form.Label htmlFor="MinOddsRate">Min. Odds Rate</Form.Label>
                                        </div>
                                    </Col>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    show={!!showTooltip?.market_max_odds_rate}
                                    placement="top"
                                    overlay={<Tooltip>{tooltipMsg.market_max_odds_rate}</Tooltip>}
                                >
                                    <Col lg={3}>
                                        <div className="form-floating">
                                            <Form.Control
                                                name="market_max_odds_rate"
                                                type="number"
                                                id="MaxOddsRate"
                                                placeholder="Max. Odds Rate"
                                                value={formData.market_max_odds_rate}
                                                onChange={handleInputChange}
                                                isInvalid={!!errors.market_max_odds_rate}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.market_max_odds_rate}</Form.Control.Feedback>
                                            <Form.Label htmlFor="MaxOddsRate">Max. Odds Rate</Form.Label>
                                        </div>
                                    </Col>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    show={!!showTooltip?.market_max_profit}
                                    placement="top"
                                    overlay={<Tooltip>{tooltipMsg.market_max_profit}</Tooltip>}
                                >
                                    <Col lg={3}>
                                        <div className="form-floating">
                                            <Form.Control
                                                name="market_max_profit"
                                                type="number"
                                                id="MarketMaxProfit"
                                                placeholder="Market Max Profit"
                                                value={formData.market_max_profit}
                                                onChange={handleInputChange}
                                                isInvalid={!!errors.market_max_profit}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.market_max_profit}</Form.Control.Feedback>
                                            <Form.Label htmlFor="MarketMaxProfit">Market Max Profit</Form.Label>
                                        </div>
                                    </Col>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    show={!!showTooltip?.market_advance_bet_stake}
                                    placement="top"
                                    overlay={<Tooltip>{tooltipMsg.market_advance_bet_stake}</Tooltip>}
                                >
                                    <Col lg={3}>
                                        <div className="form-floating">
                                            <Form.Control
                                                name="market_advance_bet_stake"
                                                type="number"
                                                id="InplayMatchStake"
                                                placeholder="Before Inplay Match Stake"
                                                value={formData.market_advance_bet_stake}
                                                onChange={handleInputChange}
                                                isInvalid={!!errors.market_advance_bet_stake}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.market_advance_bet_stake}</Form.Control.Feedback>
                                            <Form.Label htmlFor="InplayMatchStake">Before Inplay Match Stake</Form.Label>
                                        </div>
                                    </Col>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    show={!!showTooltip?.min_volume_limit}
                                    placement="top"
                                    overlay={<Tooltip>{tooltipMsg.min_volume_limit}</Tooltip>}
                                >
                                    <Col lg={3}>
                                        <div className="form-floating">
                                            <Form.Control
                                                name="min_volume_limit"
                                                type="number"
                                                id="MinVolumeStake"
                                                placeholder="Min Volume Stake"
                                                value={formData.min_volume_limit}
                                                onChange={handleInputChange}
                                                isInvalid={!!errors.min_volume_limit}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.min_volume_limit}</Form.Control.Feedback>
                                            <Form.Label htmlFor="MinVolumeStake">Min Volume Stake</Form.Label>
                                        </div>
                                    </Col>
                                </OverlayTrigger>

                                <div className='d-flex'>
                                    <h5 className='text-primary mb-0'>Advance Market Stake Setting</h5>
                                    <ToggleSwitch On='On' Off='Off'  checked={advanceMarket} className='ms-2' onChange={() => setAdvanceMarket(!advanceMarket)} />
                                </div>
                                <OverlayTrigger
                                    show={!!showTooltip?.inplay_max_volume_stake_0_10}
                                    placement="top"
                                    overlay={<Tooltip>{tooltipMsg.inplay_max_volume_stake_0_10}</Tooltip>}
                                >
                                    <Col lg={3}>
                                        <div className="form-floating">
                                            <Form.Control
                                                name="inplay_max_volume_stake_0_10"
                                                type="number"
                                                placeholder="Max Stake (0-10k gbp)"
                                                value={formData.inplay_max_volume_stake_0_10}
                                                onChange={handleInputChange}
                                                isInvalid={!!errors.inplay_max_volume_stake_0_10}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.inplay_max_volume_stake_0_10}</Form.Control.Feedback>
                                            <Form.Label>Max Stake (0-10k gbp)</Form.Label>
                                        </div>
                                    </Col>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    show={!!showTooltip?.inplay_max_volume_stake_10_40}
                                    placement="top"
                                    overlay={<Tooltip>{tooltipMsg.inplay_max_volume_stake_10_40}</Tooltip>}
                                >
                                    <Col lg={3}>
                                        <div className="form-floating">
                                            <Form.Control
                                                name="inplay_max_volume_stake_10_40"
                                                type="number"
                                                placeholder="Max Stake (10k-40k gbp)"
                                                value={formData.inplay_max_volume_stake_10_40}
                                                onChange={handleInputChange}
                                                isInvalid={!!errors.inplay_max_volume_stake_10_40}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.inplay_max_volume_stake_10_40}</Form.Control.Feedback>
                                            <Form.Label>Max Stake (10k-40k gbp)</Form.Label>
                                        </div>
                                    </Col>
                                </OverlayTrigger>

                                <OverlayTrigger
                                    show={!!showTooltip?.inplay_max_volume_stake_40}
                                    placement="top"
                                    overlay={<Tooltip>{tooltipMsg.inplay_max_volume_stake_40}</Tooltip>}
                                >
                                    <Col lg={3}>
                                        <div className="form-floating">
                                            <Form.Control
                                                name="inplay_max_volume_stake_40"
                                                type="number"
                                                placeholder="Max Stake (>40k gbp)"
                                                value={formData.inplay_max_volume_stake_40}
                                                onChange={handleInputChange}
                                                isInvalid={!!errors.inplay_max_volume_stake_40}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.inplay_max_volume_stake_40}</Form.Control.Feedback>
                                            <Form.Label>Max Stake ({'>40k gbp'})</Form.Label>
                                        </div>
                                    </Col>
                                </OverlayTrigger>


                                <h5 className='text-primary mb-0'>Market Stake Setting</h5>
                                <OverlayTrigger
                                    show={!!showTooltip?.max_volume_stake_0_10}
                                    placement="top"
                                    overlay={<Tooltip>{tooltipMsg.max_volume_stake_0_10}</Tooltip>}
                                >
                                    <Col lg={3}>
                                        <div className="form-floating">
                                            <Form.Control
                                                name="max_volume_stake_0_10"
                                                type="number"
                                                placeholder="Max Stake (0-10k gbp)"
                                                value={formData.max_volume_stake_0_10}
                                                onChange={handleInputChange}
                                                isInvalid={!!errors.max_volume_stake_0_10}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.max_volume_stake_0_10}</Form.Control.Feedback>
                                            <Form.Label>Max Stake (0-10k gbp)</Form.Label>
                                        </div>
                                    </Col>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    show={!!showTooltip?.max_volume_stake_10_40}
                                    placement="top"
                                    overlay={<Tooltip>{tooltipMsg.max_volume_stake_10_40}</Tooltip>}
                                >
                                    <Col lg={3}>
                                        <div className="form-floating">
                                            <Form.Control
                                                name="max_volume_stake_10_40"
                                                type="number"
                                                placeholder="Max Stake (10k-40k gbp)"
                                                value={formData.max_volume_stake_10_40}
                                                onChange={handleInputChange}
                                                isInvalid={!!errors.max_volume_stake_10_40}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.max_volume_stake_10_40}</Form.Control.Feedback>
                                            <Form.Label>Max Stake (10k-40k gbp)</Form.Label>
                                        </div>
                                    </Col>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    show={!!showTooltip?.max_volume_stake_40}
                                    placement="top"
                                    overlay={<Tooltip>{tooltipMsg.max_volume_stake_40}</Tooltip>}
                                >
                                    <Col lg={3}>
                                        <div className="form-floating">
                                            <Form.Control
                                                name="max_volume_stake_40"
                                                type="number"
                                                placeholder="Max Stake (>40k gbp)"
                                                value={formData.max_volume_stake_40}
                                                onChange={handleInputChange}
                                                isInvalid={!!errors.max_volume_stake_40}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.max_volume_stake_40}</Form.Control.Feedback>
                                            <Form.Label>Max Stake ({'>40k gbp'})</Form.Label>
                                        </div>
                                    </Col>
                                </OverlayTrigger>

                            </>}{fancyShow && <><h5 className='text-primary mb-0'>Session Setting</h5>
                                <OverlayTrigger
                                    show={!!showTooltip?.session_min_stack}
                                    placement="top"
                                    overlay={<Tooltip>{tooltipMsg.session_min_stack}</Tooltip>}
                                ><Col lg={3}>
                                        <div className="form-floating">
                                            <Form.Control
                                                name="session_min_stack"
                                                type="number"
                                                placeholder="Session Min Stake"
                                                value={formData.session_min_stack}
                                                onChange={handleInputChange}
                                                isInvalid={!!errors.session_min_stack}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.session_min_stack}</Form.Control.Feedback>
                                            <Form.Label>Session Min Stake</Form.Label>
                                        </div>
                                    </Col>

                                </OverlayTrigger>
                                <OverlayTrigger
                                    show={!!showTooltip?.session_max_stack}
                                    placement="top"
                                    overlay={<Tooltip>{tooltipMsg.session_max_stack}</Tooltip>}
                                >
                                    <Col lg={3}>
                                        <div className="form-floating">
                                            <Form.Control
                                                name="session_max_stack"
                                                type="number"
                                                placeholder="Session Max Stake"
                                                value={formData.session_max_stack}
                                                onChange={handleInputChange}
                                                isInvalid={!!errors.session_max_stack}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.session_max_stack}</Form.Control.Feedback>
                                            <Form.Label>Session Max Stake</Form.Label>
                                        </div>
                                    </Col>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    show={!!showTooltip?.session_max_profit}
                                    placement="top"
                                    overlay={<Tooltip>{tooltipMsg.session_max_profit}</Tooltip>}
                                >
                                    <Col lg={3}>
                                        <div className="form-floating">
                                            <Form.Control
                                                name="session_max_profit"
                                                type="number"
                                                placeholder="Session Max Profit"
                                                value={formData.session_max_profit}
                                                onChange={handleInputChange}
                                                isInvalid={!!errors.session_max_profit}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.session_max_profit}</Form.Control.Feedback>
                                            <Form.Label>Session Max Profit</Form.Label>
                                        </div>
                                    </Col>
                                </OverlayTrigger>
                            </>}
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer className='justify-content-center border-top'>
                    <Button className='mx-2' onClick={handleSave}>Save</Button>
                    <Button variant='light' className='mx-2' onClick={clickHandler}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        );
    };
export default MatchSetting;