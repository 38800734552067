import { Card, Col, Dropdown } from "react-bootstrap";
import CountUp from 'react-countup';
import { FC, useState } from "react";


interface Props{
    fetchData: (type: string, days: number, query: string[]) => Promise<void>,
    data:any,
  

}

const MergedDashboard:FC<Props> = ({fetchData, data}) => {
  
    const [selectedNames, setSelectedNames] = useState<any>({
        bets: "Select",
        activeUsers: "Select",
        userCount: "Select",
        dwQuery: "Select",
    });


    const handleDate = (type: string, days: number, name: string, query: string[]) => {
        setSelectedNames((prev:any) => ({ ...prev, [type]: name }));
        fetchData(type, days, query);
    };

    const renderCard = (
        title: string, 
        type: string, 
        queries: string[], 
        keys: { first: string; second: string }, 
        labels: { first: string; second: string },
        res:string
    ) => 
     {
        return   (
        <Col xxl={3}>
            <Card className="card-height-100">
                <Card.Header className="d-flex border-bottom pb-3">
                    <h5 className="card-title mb-0 flex-grow-1">{title}</h5>
                    <div className="flex-shrink-0">
                        <Dropdown className="card-header-dropdown sortble-dropdown cursor-pointer">
                            <Dropdown.Toggle as="a" className="text-reset arrow-none mb-0">
                                <span className="text-muted dropdown-title">{selectedNames[type]}</span> <i className="mdi mdi-chevron-down ms-1"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu-end">
                                <Dropdown.Item onClick={() => handleDate(type, 1, "Today", queries)}>Today</Dropdown.Item>
                                <Dropdown.Item onClick={() => handleDate(type, 7, "This Week", queries)}>This Week</Dropdown.Item>
                                <Dropdown.Item onClick={() => handleDate(type, 30, "This Month", queries)}>This Month</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </Card.Header>
                <div className="card-body text-center d-flex justify-content-around">
                    <div>
                        {data[type]?.[res]?.length ? <h4><CountUp start={0} end={data[type]?.[res]?.[0][keys.first]} /></h4> : <h4>--</h4>}
                        <p className="text-muted mb-0">{labels.first}</p>
                    </div>
                    {
                        keys?.second  && <div>
                        {data[type]?.[res]?.length && keys?.second ? <h4><CountUp start={0} end={data[type]?.[res]?.[0][keys.second]} /></h4> : <h4>--</h4>}
                        <p className="text-muted mb-0">{labels.second}</p>
                    </div> 
                    }
                    
                </div>
            </Card>
        </Col>
    )};

    return (
        <>
            {
            renderCard(
                "Total Settled Bets", 
                "bets", 
                ["query"], 
                { first: "total_bets", second: "total_volume" }, 
                { first: "No. of Settled Bets Users", second: "Total Volume" },
                "bets_data"
                )}
            {renderCard(
                "Total Active and Logged in Users", 
                "activeUsers", 
                ["activeClientsQuery"], 
                { first: "loggedInUsers", second: "active_users" }, 
                { first: "Total Logged in Users", second: "Active Users" },
                "activeClients"
                )}
            {renderCard(
                "Total Users Count", 
                "userCount", 
                ["userDataQuery"], 
                { first: "new_user", second: "old_user" }, 
                { first: "New Users", second: "Old Users" },
                "usersData",
                )}
            {renderCard(
                "Total D/W PL", 
                "dwQuery", 
                ["dwQuery"], 
                { first: "total_dw_pl", second: "" }, 
                { first: "Total D/W PL", second: "" },
                "total_dw_pl",
                )}
            
        </>
    );
};

export default MergedDashboard;
