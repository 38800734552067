import BreadCrumb from "Common/BreadCrumb";
import Loader from "Common/Loader";
import AlertSetting, { AlertSelection } from "Common/Modals/AlertSetting";
import MatchDetailTable from "Common/Tables/MatchDetailTable";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Container,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { authServices } from "Utils/auth/services";
import { settingServices } from "Utils/setting/services";

const CurrentBets = () => {
  const user_Detail = localStorage.getItem("adminDetails");
  const useridType = JSON.parse(user_Detail || "")?.user_type_id;

  const [betsDetailsFancy, setBetDetailsFancy] = useState<BetsData>();
  const [currentPage, setCurrentPage] = useState(1);
  const [formValuesFilter, setFormValuesFilter] = useState<{
    [key: string]: string;
  }>({});
  const [userNameData, setUserNameData] = useState<any[]>([]);
  const [user_name, setUser_name] = useState("");
  const [domainList, setDomainList] = useState<DomainListPayload[]>([]);
  const [domainId, setDomainId] = useState<string>("");
  const [alertData, setAlertData] = useState<AlertSelection>({
    min_amount: null,
    max_amount: null,
    min_bhav: null,
    users: [],
    isAlert: true,
  });
  const [showAlert, setAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [paylodData, setPayloadData] = useState({
    bet_id: "",
    user_id: "",
    is_void: true,
    is_fancy: 0,
    password: "",
  });

  const [limit, setLimit] = useState<number>(50);
  const [listTotal, setListTotal] = useState<ProfitLossTotal>({
    total: 0,
    page: 0,
  });
  const [betcount, setBetCount] = useState<number>(0);

  function filterBets(
    bets: Dataaa[],
    alertSelection: AlertSelection
  ): Dataaa[] {
    const { min_amount, max_amount, min_bhav, users } = alertSelection;

    const minAmount = min_amount ? parseFloat(min_amount) : null;
    const maxAmount = max_amount ? parseFloat(max_amount) : null;
    const minBhav = min_bhav ? parseFloat(min_bhav) : null;
    const selectedUserIds = users.map((user) => user.value);

    return bets.filter((bet) => {
      const isUserMatch =
        selectedUserIds.length === 0 || selectedUserIds.includes(bet.user_id);
      const isMinAmountValid = minAmount === null || bet.stack >= minAmount;
      const isMaxAmountValid = maxAmount === null || bet.stack <= maxAmount;
      const isMinBhavValid = minBhav === null || bet.odds >= minBhav;

      return (
        isUserMatch && isMinAmountValid && isMaxAmountValid && isMinBhavValid
      );
    });
  }

  const getBetsData = async (
    pageSize: number,
    currentPage: number,
    isBeep?: boolean
  ) => {
    const { market_name, is_back, odds, stack, bet_id } = formValuesFilter;
    const searchQuery: any = {
      bet_result_id: { $eq: null },
      is_fancy: 1,
      is_matched: 1,
      ...(user_name && { user_name }),
      ...(domainId &&
        domainId.length > 3 && {
          domain_name: domainId?.trim()?.toLowerCase(),
        }),
      ...(market_name && { selection_name: market_name }),
      ...(is_back && { is_back }),
      ...(bet_id && { ip_address: bet_id }),
      ...(odds && { odds }),
      ...(stack && { stack }),
    };
    const { response } = await authServices.getBets({
      limit: limit,
      page: currentPage,
      search: searchQuery,
    });
    setIsLoading(false);
    const betData = (response && response?.data) || [];
    if (betData && betData.metadata && betData.metadata.length > 0) {
      setListTotal({
        total: betData.metadata[0]?.total
          ? betData.metadata[0].total / limit
          : 0,
        page: betData.metadata[0]?.page || 0,
      });
    } else {
      setListTotal({
        total: 0,
        page: 0,
      });
    }

    setBetDetailsFancy(betData);
    let oldArray = betsDetailsFancy?.data || [];

    if (betData && betData.metadata && betData.metadata.length > 0) {
      const oldBetIds = new Set(oldArray.map((item) => item.bet_id));
      const newBetIdsNotInOld = betData?.data.filter(
        (item: Dataaa) => !oldBetIds.has(item.bet_id)
      );
      if (newBetIdsNotInOld?.length > 0) {
        let isAllowed = alertData
          ? filterBets(newBetIdsNotInOld, alertData)
          : [];

        if (
          alertData &&
          alertData?.isAlert &&
          isAllowed?.length > 0 &&
          isBeep
        ) {
          beepOnce();
        }
      }

      if (betData?.metadata[0]?.total !== betcount) {
        setBetCount(betData?.metadata[0]?.total);
      }
    }
  };

  const beepOnce = () => {
    const audio = new Audio("/beep/beep.mp3");
    audio.play();
  };

  const getUserbyUserName = async (user_name: string) => {
    const { response } = await settingServices.getUserbyUserName({
      user_name: user_name?.trim()?.toLowerCase(),
    });
    const options = response?.data.map((user: any) => ({
      value: user?.user_name,
      label: user?.user_name,
    }));
    setUserNameData(options);
  };

  const handleSelectUser = (selectedOption: any) => {
    setIsLoading(true);
    if (selectedOption) {
      setUser_name(selectedOption.value);
    }
  };

  const handleUser = (inputValue: string) => {
    if (inputValue?.length > 3) {
      getUserbyUserName(inputValue);
    }
  };

  useEffect(() => {
    getBetsData(limit, currentPage);
    const intervalId = setInterval(() => {
      getBetsData(limit, currentPage, true);
    }, 1000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, [
    currentPage,
    limit,
    formValuesFilter,
    user_name,
    domainId,
    betcount,
    alertData,
  ]);

  const getAllWebsite = async () => {
    const { response } = await authServices.getAllWebsite();
    const options = response?.data.map((user: any) => ({
      value: user?.domain_name,
      label: user.host_name,
    }));
    setDomainList(options);
  };

  useEffect(() => {
    getAllWebsite();
  }, []);

  const handleDomain = (selectedOption: any) => {
    setCurrentPage(1);
    setIsLoading(true);
    const selectedValue = selectedOption ? selectedOption.value : "";
    setDomainId(selectedValue);
  };

  const handlePageFilter = (page: number, limit: number) => {
    setCurrentPage(page);
    setLimit(limit);
    setIsLoading(true);
  };

  const toggleAlert = () => {
    setAlert(!showAlert);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <div className="position-relative mb-2 d-flex justify-content-between">
          <BreadCrumb title="Current Bets" pageTitle="Dashboard" back />
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-top"> {"Alert Setting"} </Tooltip>}
          >
            <Button variant="btn-ghost py-1 px-2 me-1" onClick={toggleAlert}>
              <i className="bi bi-bell fs-2xl"></i>
            </Button>
          </OverlayTrigger>
        </div>
        <Card>
          <Card.Body>
            <MatchDetailTable
              betsDetails={betsDetailsFancy}
              getBetsData={getBetsData}
              setPayloadData={setPayloadData}
              paylodData={paylodData}
              currentPage={currentPage}
              userNameData={userNameData}
              handleUser={handleUser}
              userName={user_name}
              formValuesFilter={formValuesFilter}
              setFormValuesFilter={(value: any) => {
                setCurrentPage(1);
                setFormValuesFilter(value);
              }}
              page={currentPage}
              limit={limit}
              listTotal={listTotal}
              handlePageFilter={handlePageFilter}
              handleSelectUser={handleSelectUser}
              setUser_name={setUser_name}
              useridType={useridType}
              domainList={domainList}
              domainId={domainId}
              handleDomain={handleDomain}
              betcount={betcount}
            />
          </Card.Body>
        </Card>
        {isLoading && <Loader />}
      </Container>

      <AlertSetting
        marketName={"Current Bets"}
        show={showAlert}
        clickHandler={toggleAlert}
        limits={alertData}
        setAlertData={setAlertData}
      />
    </div>
  );
};

export default CurrentBets;
