import TableEntry from "Common/TableEntry";
import { useEffect, useMemo, useState } from "react";
import { Button, Modal, Nav, Tab, Table, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { authServices } from "Utils/auth/services";

interface ExposureModalProps {
  show: any;
  clickHandler: any;
  userIds: string;
  userName: string;
}

const ExposureModal = ({
  show,
  clickHandler,
  userIds,
  userName,
}: ExposureModalProps) => {
  const [sportsData, setSportsData] = useState<fullExpData>();
  const [casinoData, setCasinoData] = useState<any[]>([]);
  const [fullExp, setFullExc] = useState<boolean>(true);

  const fetchExposureSport = async () => {
    try {
      const { response } = await authServices.getExposureSport({
        user_id: userIds,
      });
     
      setSportsData(response);
    } catch (error) {
      console.error("Error fetching exposure sport data:", error);
    }
  };

  // const fetchExposureSportV1 = async () => {
  //   try {
  //     const { response } = await authServices.getExposureSportV1({
  //       user_id: userIds,
  //     });
  //     const filteredData = response.data.filter(
  //       (item: SportData) => !("liabilitySum" in item)
  //     );
  //     setSportsData(filteredData);
  //   } catch (error) {
  //     console.error("Error fetching exposure sport data:", error);
  //   }
  // };
  const fetchExposureCasino = async () => {
    try {
      const { response } = await authServices.getExposureCasino({
        user_id: userIds,
      });
      setCasinoData(response.data);
    } catch (error) {
      console.error("Error fetching exposure casino data:", error);
    }
  };

  useEffect(() => {
    if (show) {
        fetchExposureSport();
    }
  }, [show]);

  const nav = useNavigate();

  const handleRoute = (
    matchid: string,
    sport_id: string,
    matchName: string,
    isActive: number
  ) => {
    nav(`/match-detail/${matchid}/${sport_id}`, { state: matchName });
    localStorage.setItem("isAct", String(isActive));
    clickHandler();
  };

  const renderTableRows = (data: any) =>
    data?.map((item:any, index:number) => (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{item.sport_name}</td>
        <td
          className="cursor-pointer"
          onClick={() =>
            handleRoute(item?.match_id, item?.sport_id, item?.match_name, 0)
          }
        >
          {item.match_name}
        </td>
        <td>{item.event_name}</td>
        <td style={{ color: (!fullExp? item?.liability_share?.toFixed(2):item.liability?.toFixed(2)) < 0 ? "red" : "black" }}>
          {!fullExp? item?.liability_share?.toFixed(2):item.liability?.toFixed(2)}
        </td>
      </tr>
    ));



  const totalsCasino = useMemo(() => {
    return casinoData?.reduce(
      (acc, user) => {
        acc.calculateExposure += user.calculateExposure;
        return acc;
      },
      { calculateExposure: 0 }
    );
  }, [casinoData]);

  return (
    <Modal
      size="lg"
      show={show}
      onHide={clickHandler}
      className="zoomIn"
      scrollable
    >
      <Modal.Header className="modal-title fw-bold d-flex justify-content-between">
        <div className="d-flex">
          <span>Exposure of {userName}</span>
          <Form.Check
            checked={fullExp}
            onChange={(e) => setFullExc(e.target.checked)}
            type="checkbox"
            role="switch"
            className="ms-3"
            id="tableOnOff"
          />
        </div>

        <Button variant="light btn-sm" onClick={clickHandler}>
          <i className="ri-close-line fs-xl align-middle"></i>
        </Button>
      </Modal.Header>
      <Modal.Body className="pt-1">
        <Tab.Container defaultActiveKey="sports">
          <Nav
            as="ul"
            variant="tabs"
            className="nav-tabs-custom nav-primary nav-justified mb-3"
          >
            <Nav.Item as="li">
              <Nav.Link eventKey="sports" onClick={fetchExposureSport}>
                Sports
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link eventKey="casino" onClick={fetchExposureCasino}>
                Casino
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className="text-muted">
            <Tab.Pane eventKey="sports">
              {sportsData?.data?.length === 0 ? (
                <div
                  style={{
                    fontSize: "14",
                    textAlign: "center",
                  }}
                >
                  No Data Found
                </div>
              ) : (
                <Table className="table-striped table-nowrap align-middle mb-0">
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>Sport Name</th>
                      <th>Event Name</th>
                      <th>Market</th>
                      <th>Exposure</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderTableRows(sportsData?.data)}
                    <tr>
                      <td colSpan={4} className="fw-bolder text-center">
                        Total
                      </td>
                      <td
                        className={`fw-bolder ${
                          (sportsData && fullExp?sportsData?.total_liability:sportsData?.total_liability_share ?? 0) < 0
                            ? "text-danger"
                            : "text-secondary"
                        }`}
                      >
                        {sportsData && fullExp?sportsData?.total_liability?.toFixed(2):sportsData?.total_liability_share?.toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              )}
            </Tab.Pane>
            <Tab.Pane eventKey="casino">
              {casinoData?.length === 0 ? (
                <div
                  style={{
                    fontSize: "14",
                    textAlign: "center",
                  }}
                >
                  No Data Found
                </div>
              ) : (
                <Table className="table-striped table-nowrap align-middle mb-0">
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>Sport Name</th>
                      <th>Event Name</th>
                      <th>Market</th>
                      <th>Exposure</th>
                    </tr>
                  </thead>
                  <tbody>
                    {casinoData?.map((casino, id: number) => {
                      return (
                        <tr key={id}>
                          <td>{id + 1}</td>
                          <td>{casino?.matchName}</td>
                          <td>{casino?.marketName}</td>
                          <td>{casino?.marketType}</td>
                          <td>
                            <span
                              className={
                                casino?.calculateExposure < 0
                                  ? "text-danger"
                                  : "text-secondary"
                              }
                            >
                              {casino?.calculateExposure?.toFixed(2)}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td colSpan={4} className="fw-bolder text-center">
                        Total
                      </td>
                      <td
                        className={`fw-bolder ${
                          totalsCasino?.calculateExposure < 0
                            ? "text-danger"
                            : "text-secondary"
                        }`}
                      >
                        {totalsCasino?.calculateExposure?.toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              )}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Modal.Body>
    </Modal>
  );
};

export default ExposureModal;
