import BreadCrumb from "Common/BreadCrumb";
import { useState } from "react";
import { Container } from "react-bootstrap";
import { settingServices } from "Utils/setting/services";
import CasinoBets from "pages/Reports/CasinoBets";
import snackbarUtil from "Utils/snackBarUtil";

const CasinoResult = () => {
  const [actionType, setActionType] = useState("");
  const [marketIdAction, setActionMarketId] = useState("");

  const getRoundStatus = async (objectId: string) => {
    const { response } = await settingServices.getRoundStatus({ objectId });
    if (response) {
      if (response?.status) {
        snackbarUtil.success(response?.msg);
        setActionType(response?.actionType);
        setActionMarketId(response?.data?.marketId);
      } else {
        snackbarUtil.error(response?.msg);
      }
    }

  };

  const handleStatusUpdate = (id: string) => {
    getRoundStatus(id);
  };


  return (
    <div className="page-content">
      <Container fluid>
       <BreadCrumb title="Casino Result" pageTitle="Casino" />
        {/*  <Card>
          <Card.Body>
            <Row className="mb-2">
              <Col lg={3} className="mb-2 mb-lg-0">
                <Select
                  options={userOption}
                  placeholder="Select User"
                  onChange={(selected: any) => {
                    if (selected) {
                      setSelectedUserId(selected.value);
                    }
                  }}
                />
              </Col>
              <Col lg={3} className="mb-2 mb-lg-0">
                <Flatpickr
                  className="form-control"
                  value={startDate}
                  options={{
                    dateFormat: "Y-m-d",
                  }}
                  onChange={(date: any) => {
                    setStartDate(date[0].toISOString());
                  }}
                />
              </Col>
              <Col lg={3} className="mb-2 mb-lg-0">
                <Flatpickr
                  className="form-control"
                  value={endDate}
                  options={{
                    dateFormat: "Y-m-d",
                  }}
                  onChange={(date: any) => {
                    setEndDate(date[0].toISOString());
                  }}
                />
              </Col>
              <Col lg={3} className="text-end">
                <Button
                  variant="primary"
                  onClick={() => getLotusBets(startDate, endDate)}
                >
                  Search
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        
        <Card>
          <Card.Body>
            <CasinoResultTable
              betData={betData}
              getLotusBets={getLotusBets}
              startDate={startDate}
              endDate={endDate}
              selectedUserId={selectedUserId}
              page={page}
              limit={limit}
              listTotal={listTotal}
              handlePageFilter={handlePageFilter}
            />
          </Card.Body>
        </Card> */}

        <CasinoBets marketIdAction={marketIdAction} actionType={actionType} handleStatusUpdate={handleStatusUpdate} filter={true} activeTab="CasinoBets" showAction={true}/>
      </Container>
    </div>
  );
};

export default CasinoResult;
