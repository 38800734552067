import BreadCrumb from "Common/BreadCrumb";
import TotalWithdrawTable from "Common/Tables/TotalWithdrawTable";
import WithdrawTable from "Common/Tables/WithdrawTable";
import { useEffect, useState } from "react";
import { Container, Card, Row, Col, Form, Button } from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import { authServices } from "Utils/auth/services";
import { currentDateTime, getStartTimeOfDay } from "Utils/datefilter";

const Withdraw = () => {
  const userId = localStorage.getItem("userId");
  const currentDate = new Date();
  const sevenDaysAgo = new Date(currentDate.setDate(currentDate.getDate() - 7));

  const [startDate, setStartDate] = useState<string | Date>(
    getStartTimeOfDay(sevenDaysAgo)
  );
  const [endDate, setEndDate] = useState<string | Date>(currentDateTime());
  const [limit, setLimit] = useState<number>(50);
  const [page, setPage] = useState<number>(1);
  const [listTotal, setListTotal] = useState<ProfitLossTotal>({
    total: 0,
    page: 0,
  });
  const [withdrawData, setWithdrawData] = useState<WithdrawData[]>([]);
  const [userName, setUserName] = useState<string>("");
  const [parentName, setParentName] = useState<string>("");
  const [minAmount, setMinAmount] = useState<string>("");
  const [maxAmount, setMaxAmount] = useState<string>("");
  const [type, setType] = useState<string>("ALL");

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value);
  };

  const handleParentNameeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setParentName(event.target.value);
  };

  const handleMaxAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMaxAmount(event.target.value);
  };

  const handleMinAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMinAmount(event.target.value);
  };

  const handleTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const type = event.target.value;
    setType(type);
    setPage(1);

    getAllDeposit({
      ...(minAmount && { lowestAmount: Number(minAmount) }),
      ...(maxAmount && { highestAmount: Number(maxAmount) }),
      search: {
        ...(userName && { user_name: userName }),
        ...(parentName && { parent_user_name: parentName }),
      },
      page: 1,
      status: type,
    });
  };

  const getAllDeposit = async (filters: any = {}) => {
    const { response } = await authServices.getWithdrawDeposit({
      from_date: startDate,
      to_date: endDate,
      status: type,
      user_id: userId || "",
      search: {
        ...(userName && { user_name: userName }),
        ...(parentName && { parent_user_name: parentName }),
      },
      limit: limit,
      page: page,
      ...filters,
    });
    if (response?.status && response?.data) {
      setWithdrawData(response?.data?.data);
      setListTotal({
        total: response?.data?.total / response?.data?.limit || 0,
        page: response?.data?.page || 1,
      });
    } else {
      setWithdrawData([]);
      setListTotal({
        total: 0,
        page: 1,
      });
    }
  };

  useEffect(() => {
    getAllDeposit({});
    // eslint-disable-next-line
  }, []);

  const filterSubmit = () => {
    setPage(1);

    getAllDeposit({
      ...(minAmount && { lowestAmount: Number(minAmount) }),
      ...(maxAmount && { highestAmount: Number(maxAmount) }),
      page: 1,
    });
  };

  const clearFilter = () => {
    setUserName("");
    setParentName("");
    setMinAmount("");
    setMaxAmount("");
    setType("ALL");
    setStartDate(getStartTimeOfDay(sevenDaysAgo));
    setEndDate(currentDateTime());
    setPage(1);

    getAllDeposit({
      from_date: getStartTimeOfDay(sevenDaysAgo),
      to_date: currentDateTime(),
      status: "ALL",
      page: 1,
      search: {},
    });
  };

  const handlePageFilter = (page: number, limit: number) => {
    setPage(page);
    setLimit(limit);

    getAllDeposit({
      ...(minAmount && { lowestAmount: Number(minAmount) }),
      ...(maxAmount && { highestAmount: Number(maxAmount) }),
      page: page,
      limit: limit,
    });
  };

  return (
    <div className="page-content">
      <Container fluid>
        <div className="position-relative mb-3 d-flex justify-content-between align-items-center">
          <BreadCrumb title="Withdraw" pageTitle="Dashboard" back />
        </div>
        <WithdrawTable />

        <Card className="mt-3">
          <Card.Header className="border-bottom pb-3">
            <Row className="align-items-end g-3">
              <Col lg={3}>
                <Form.Label>Start Date</Form.Label>
                <Flatpickr
                  className="form-control"
                  options={{
                    enableTime: true,
                    dateFormat: "Y-m-d H:i:s",
                    defaultDate: [startDate],
                    maxDate: endDate,
                  }}
                  value={startDate}
                  onChange={([date]: [Date]) => {
                    setStartDate(new Date(date));
                  }}
                />
              </Col>
              <Col lg={3}>
                <Form.Label>End Date</Form.Label>
                <Flatpickr
                  className="form-control"
                  options={{
                    enableTime: true,
                    dateFormat: "Y-m-d H:i:s",
                    defaultDate: [endDate],
                    maxDate: currentDateTime(),
                  }}
                  value={endDate}
                  onChange={([date]: [Date]) => {
                    setEndDate(new Date(date));
                  }}
                />
              </Col>
              <Col lg={3}>
                <Form.Label>Min Amount</Form.Label>
                <Form.Control
                  type="number"
                  value={minAmount}
                  placeholder="Enter Min Amount"
                  onChange={handleMinAmountChange}
                />
              </Col>
              <Col lg={3}>
                <Form.Label>Max Amount</Form.Label>
                <Form.Control
                  type="number"
                  value={maxAmount}
                  placeholder="Enter Max Amount"
                  onChange={handleMaxAmountChange}
                />
              </Col>
              <Col lg={3}>
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  value={userName}
                  placeholder="Enter Username"
                  onChange={handleUsernameChange}
                />
              </Col>
              <Col lg={3}>
                <Form.Label>Parent Username</Form.Label>
                <Form.Control
                  type="text"
                  value={parentName}
                  placeholder="Enter Parent Username"
                  onChange={handleParentNameeChange}
                />
              </Col>
              <Col lg={3}>
                <Form.Label>Parent Username</Form.Label>
                <div className="d-flex">
                  <Form.Select
                    className="me-1 w-75"
                    value={type}
                    onChange={handleTypeChange}
                  >
                    <option value="ALL">All</option>
                    <option value="ACCEPTED">Accepted</option>
                    <option value="REJECTED">Rejected</option>
                  </Form.Select>
                </div>
              </Col>
              <Col lg={3}>
                <Button className="me-2" onClick={filterSubmit}>
                  Search
                </Button>
                <Button variant="subtle-primary" onClick={clearFilter}>
                  Clear
                </Button>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <TotalWithdrawTable
              withdrawData={withdrawData}
              page={page}
              limit={limit}
              listTotal={listTotal}
              handlePageFilter={handlePageFilter}
            />
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default Withdraw;
