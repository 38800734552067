import BreadCrumb from "Common/BreadCrumb";
import { Button, Card, Col, Container, Form, Row, Table } from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import {  useState } from "react";
import TotalPL from "./TotalPL";
import TopDeposit from "./TopDeposit";
import TopWinner from "./TopWinner";
import TopLosser from "./TopLosser";
import TopGamesPlayed from "./TopGamesPlayed";
import TotalOpenBets from "./TotalOpenBets";
import TopCasinoGamesPlayed from "./TopCasinoGamesPlayed";
import TrafficAnalysis from "./TrafficAnalysis";
import TotalBetsCountWithPL from "./TotalBetsCountWithPL";
import { settingServices } from "Utils/setting/services";
import { currentDateTime, getStartTimeOfDay } from "Utils/datefilter";
import MergedDashboard from "./MergedDashboard";
import TransactionStatus from "./TransactionStatus";
import moment from "moment";
import Loader from "Common/Loader";

interface sportPlPayload {
    sport_id: string,
    sport_name: string,
    profit_loss: number

}

const B2CReport = () => {
    const currentDate = new Date();
    const sevenDaysAgo = new Date(currentDate.setDate(currentDate.getDate() - 7));

    const [startDate, setStartDate] = useState<string | Date>(
        getStartTimeOfDay(sevenDaysAgo)
    );
    const [endDate, setEndDate] = useState<string | Date>(currentDateTime());
    const [sportData, setSportData] = useState<sportPlPayload[]>([])
    const [data, setData] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = async (type: string, days: number, query: string[]) => {
        setIsLoading(true);
        const { response } = await settingServices.getTotalReport({
            from_date: moment().subtract(days, "days"),
            to_date: moment(),
            limit: 20,
            search: query,
        });

        if (response) {
            setIsLoading(false);
            setData((prevData: any) => ({
                ...prevData,
                [type]: response.data,
            }));
        }
    };

    const user_Detail = localStorage.getItem("adminDetails");
    let userid = JSON.parse(user_Detail || '')?._id;

    const getSportWisePL = async () => {
        setIsLoading(true);
        const { response } = await settingServices.getSportByPl({
            from_date: startDate,
            to_date: endDate,
            user_id: userid,
        });

        if (response) {
            setIsLoading(false);
            setSportData(response?.data)
        }
    };

    const handleSportPl = () => {
        getSportWisePL();
    }


    return (
        <div className="page-content">
            <Container fluid>
                <div className="position-relative mb-2">
                    <BreadCrumb title="B2C Report" pageTitle="Dashboard" back />

                    <Card>
                        <Card.Body>
                            <Form>
                                <Row className="gy-3 align-items-end">
                                    <Col lg={3}>
                                        <Form.Label>Start Date</Form.Label>
                                        <Flatpickr
                                            className="form-control"
                                            placeholder="2024-12-06 05:17:44"
                                            pl
                                            options={{
                                                enableTime: true,
                                                dateFormat: "Y-m-d H:i:s",
                                                defaultDate: [startDate],
                                                maxDate: endDate,
                                            }}
                                            value={startDate}
                                            onChange={([date]: [Date]) => {
                                                setStartDate(new Date(date));
                                            }}
                                        />
                                    </Col>
                                    <Col lg={3}>
                                        <Form.Label>End Date</Form.Label>
                                        <Flatpickr
                                            className="form-control"
                                            placeholder="2024-12-06 05:17:44"
                                            pl
                                            options={{
                                                enableTime: true,
                                                dateFormat: "Y-m-d H:i:s",
                                                defaultDate: [endDate],
                                                maxDate: currentDateTime(),
                                            }}
                                            value={endDate}
                                            onChange={([date]: [Date]) => {
                                                setEndDate(new Date(date));
                                            }}
                                        />
                                    </Col>
                                    <Col lg={3}>
                                        <Button
                                            className="me-2 btn-sm"
                                            style={{ height: "38px" }}
                                            onClick={handleSportPl}
                                        >
                                            <i className="fs-lg align-middle ri-filter-line"></i> Submit
                                        </Button>
                                        <Button
                                            variant="outline-primary"
                                            className="btn-sm"
                                            style={{ height: "38px" }}
                                        >
                                            <i className="fs-lg align-middle ri-refresh-line"></i> Clear
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                    {
                        sportData?.length !== 0 && <Col xxl={6}>
                            <Card className="card-height-100">
                                <div className="card-body">
                                    <div className="table-resposive">
                                        <Table>
                                            <thead className="table-light">
                                                <tr>
                                                    <th>Sports</th>
                                                    <th>P/L</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {sportData?.length !== 0 ? sportData?.map((item: any) => {
                                                    return (
                                                        <tr>
                                                            <td>{item?.sport_name}</td>
                                                            <td className={item?.profit_loss < 0 ? "text-danger" : ""}>{item?.profit_loss}</td>

                                                        </tr>
                                                    )
                                                }) : <tr>
                                                    <td colSpan={4} className="text-center">No Data Found!</td>
                                                </tr>}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    }
                    <Row>
                        <MergedDashboard data={data}  fetchData={fetchData} />
                        <TransactionStatus />
                        <TotalPL data={data}  fetchData={fetchData} />
                        <TopDeposit data={data} fetchData={fetchData} />
                        <TopWinner data={data}  fetchData={fetchData} />
                        <TopLosser data={data}  fetchData={fetchData} />
                        <TopGamesPlayed data={data} fetchData={fetchData} />
                        <TotalOpenBets data={data} fetchData={fetchData} />
                        <TopCasinoGamesPlayed data={data} fetchData={fetchData} />
                        <TrafficAnalysis data={data} fetchData={fetchData} />
                        <TotalBetsCountWithPL data={data} fetchData={fetchData} />
                    </Row>
                </div>
                {isLoading && <Loader />}
            </Container>
        </div>
    );
};

export default B2CReport;