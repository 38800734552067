import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Button, Card, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

interface Props {
    userData: DataRes | undefined;
    setUserPayload: Dispatch<SetStateAction<{
        partnership: number;
        domain: string;
        domain_name: string;
        name: string;
        user_name: string;
        password: string;
        user_type_id: string;
        parent_id: string;
        point: number;
        exposure_limit: number;
        belongs_to_credit_reference: number;
        credit_reference: number;
        belongs_to: string;
        title: string;
        session_commission: number;
        match_commission: number;
    }>>;
    userPayload: {
        partnership: number;
        domain: string;
        domain_name: string;
        name: string;
        user_name: string;
        password: string;
        user_type_id: string;
        parent_id: string;
        point: number;
        exposure_limit: number;
        belongs_to_credit_reference: number;
        credit_reference: number;
        belongs_to: string;
        title: string;
        session_commission: number;
        match_commission: number;
    };
    errors: {
        [key: string]: string;
    }
    setErrors: Dispatch<SetStateAction<{
        [key: string]: string;
    }>>
    type: string,
    userType: string | undefined
    setDemoUser: Dispatch<SetStateAction<boolean>>
    demoUser: boolean,
    demo: boolean | undefined
    getCheckUserName: any
}

const PersonalDetails: FC<Props> = ({ getCheckUserName, setDemoUser, demoUser, demo, setErrors, errors, userData, setUserPayload, userPayload, type, userType }) => {
    const [passwordShow, setPasswordShow] = useState<boolean>(false);
    const [passwordTooltipVisible, setPasswordTooltipVisible] = useState(false);
    const [userDetails, setUserDetails] = useState<any>(null);
    const user_id = localStorage.getItem("userId");
    const validatePassword = (password: string) => {
        if (password.length < 8) {
            return "Password must be at least 8 characters long.";
        }
        const allowedCharsRegex = /^[a-zA-Z0-9@_#$-]+$/;
        if (!allowedCharsRegex.test(password)) {
            return "Password can only contain letters, numbers, and @, _, #, &, -.";
        }
        if (!/[a-zA-Z]/.test(password)) {
            return "Password must contain at least one letter.";
        }
        if (!/[0-9]/.test(password)) {
            return "Password must contain at least one number.";
        }
        return "";
    };

    const handlePasswordBlur = () => {
        const errorMsg = validatePassword(userPayload.password);
        setErrors(prevErrors => ({
            ...prevErrors,
            password: errorMsg,
        }));
    };

    useEffect(() => {
        if (userData?.user_name) {
            setUserPayload((prevState) => ({
                ...prevState,
                parent_id: user_id || "",
            }));
        }
         // eslint-disable-next-line
    }, [user_id, setUserPayload]);

    const handleInputChange = (e: any) => {
        const { name, value, type, checked } = e.target;

        if (name === "domain_name") {
            const selectedDomain = userData?.domain?.find((domain: any) => domain.domain_name === value);
            if (selectedDomain) {
                setUserPayload((prevState) => ({
                    ...prevState,
                    domain: selectedDomain._id,
                    domain_name: value,
                }));
            }
        } else if (name === "user_type_id") {
            const numValue = parseInt(value);
            if (numValue < 0) {
                setErrors((prevErrors) => ({ ...prevErrors, [name]: "User type cannot be negative." }));
            } else {
                setUserPayload((prevState) => ({
                    ...prevState,
                    [name]: numValue,
                }));
                setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
            }
        } else if (name === "belongs_to_credit_reference" && e.target.id === "BelongToUkraine") {
            setUserPayload((prevState) => ({
                ...prevState,
                belongs_to_credit_reference: checked ? 1 : 0,
                user_type_id: checked ? "8" : prevState.user_type_id,

            }));
        } else if (name === "belongs_to_credit_reference" && e.target.id === "BelongToB2C") {
            setUserPayload((prevState) => ({
                ...prevState,
                belongs_to_credit_reference: checked ? 1 : 0,
                // user_type_id: "4"
                 user_type_id: checked ? type==="b2c"?"4":"0" : prevState.user_type_id,
                // title:"Super Manager"
            }));
        } else {
            setUserPayload((prevState) => ({
                ...prevState,
                [name]: type === "checkbox" ? (checked ? 1 : 0) : value,
            }));
            if (name === "belongs_to" && value.toLowerCase() === "diamond") {
                setUserPayload((prevState) => ({
                    ...prevState,
                    belongs_to_credit_reference: 1,
                }));
            }
        }
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    };
    const handleSelectClient = (e: any) => {
        const selectedValue = e.target.value;
        const selectedName = e.target.options[e.target.selectedIndex].text;
        setUserPayload((prevState) => ({
            ...prevState,
            user_type_id: selectedValue,
            title: selectedName,
        }));
        setErrors((prevErrors) => ({ ...prevErrors, user_type_id: "" }));
    }

    useEffect(() => {
        const user = localStorage.getItem("adminDetails");
        if (user) {
            try {
                const parsedUser = JSON.parse(user);
                setUserDetails(parsedUser);
            } catch (error) {
                console.error("Failed to parse user details", error);
            }
        }
    }, []);


    useEffect(() => {
        if (userType) {
            setUserPayload((prevState) => ({
                ...prevState,
                domain_name: userDetails?.domain_name,
            }))
        }
         // eslint-disable-next-line
    }, [userType])

    const labels = [
        {
            name: "CHIP_SUMMARY",
            label: "CHIP_SUMMARY"
        },
        {
            label: "DIAMOND",
            name: "DIAMOND/SKY/LOTUS/RADHE"
        },
    ]

    const handleOnBlur = () => {
        getCheckUserName(userPayload.user_name);
    }

    const { typeId} = useParams();
    const user_Detail = localStorage.getItem("adminDetails");
    let userTypeId = JSON.parse(user_Detail || '')?.user_type_id;

    console.log(userData,userTypeId, "userDatauserData")

    return (
        <Card>
            <Card.Header>
                <h5 className="text-primary mb-0">Personal Details</h5>
            </Card.Header>
            <Card.Body>
                <Form action="#">
                    <Row className="g-3">
                        {
                            (type !== "uk" && type !== "user" && type !== "b2c" && userData?.user_type_id === 0) && <Col sm={12}>
                                <div className="bg-light py-2 px-3">
                                    <p className="mb-2">Type Of Website</p>
                                    <div className="mt-0 d-flex align-items-center flex-wrap">

                                        {labels?.map((label, index) => (
                                            <div className="form-check me-3" key={index}>
                                                <Form.Check
                                                    type="checkbox"
                                                    id={label?.label}
                                                    name="belongs_to"
                                                    checked={userPayload.belongs_to === label?.label}
                                                    onChange={() => setUserPayload((prev) => (
                                                        {
                                                            ...prev,
                                                            belongs_to: label?.label
                                                        }
                                                    ))}
                                                />
                                                <Form.Label className="form-check-label" htmlFor={label?.label}>
                                                    {label?.name}
                                                </Form.Label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Col>
                        }


                        <Col lg={6}>
                            <div className="form-floating">
                                <Form.Control
                                    type="text"
                                    name="parent"
                                    placeholder="Parent Name"
                                    disabled
                                    value={userData?.user_name || ''}
                                    autoComplete="new-username"
                                />
                                <Form.Label>Parent Name</Form.Label>
                            </div>
                        </Col>

                        <Col lg={6}>
                            <div className="form-floating">
                                <Form.Control
                                    name="name"
                                    type="text"
                                    placeholder="Agent Name"
                                    onChange={handleInputChange}
                                    value={userPayload.name || ''}
                                    isInvalid={!!errors.name}
                                    autoComplete="new-olduser"
                                />
                                <Form.Label>{type === "user" ? "Client Name" : "Agent Name"}</Form.Label>
                                <Form.Control.Feedback type="invalid">
                                    {errors.name}
                                </Form.Control.Feedback>
                            </div>
                        </Col>

                        <Col lg={6}>
                            <div className="form-floating">
                                <Form.Control
                                    name="user_name"
                                    type="text"
                                    placeholder="Username"
                                    onChange={handleInputChange}
                                    value={userPayload.user_name || ''}
                                    isInvalid={!!errors.user_name}
                                    onBlur={handleOnBlur}
                                    autoComplete="new_olduser"
                                    id="new_olduser"
                                />
                                <Form.Label>Username</Form.Label>
                                <Form.Control.Feedback type="invalid">
                                    {errors.user_name}
                                </Form.Control.Feedback>
                            </div>
                        </Col>

                        <Col lg={6}>
                            <div className="form-floating">
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="password-tooltip">
                                            Example: demo1234
                                        </Tooltip>
                                    }
                                    show={passwordTooltipVisible}
                                >
                                    <Form.Control
                                        name="password"
                                        type={passwordShow ? "text" : "password"}
                                        placeholder="Password"
                                        onChange={handleInputChange}
                                        value={userPayload.password || ''}
                                        isInvalid={!!errors.password}
                                        onFocus={() => setPasswordTooltipVisible(true)}
                                        onBlur={() => { setPasswordTooltipVisible(false); handlePasswordBlur(); }}
                                        autoComplete="new-password"
                                        id="password-field"
                                    />
                                </OverlayTrigger>
                                <Form.Label>Password</Form.Label>
                                <Button
                                    variant="link"
                                    className="position-absolute end-0 top-0 bottom-0 text-decoration-none text-muted password-addon"
                                    type="button"
                                    id="password-addon"
                                    onClick={() => setPasswordShow(!passwordShow)}
                                >
                                    <i className={`${passwordShow ? "ri-eye-fill" : "ri-eye-off-fill"} align-middle`}></i>
                                </Button>
                                <Form.Control.Feedback type="invalid">
                                    {errors.password}
                                </Form.Control.Feedback>
                            </div>
                        </Col>
                        {
                            ( (userData?.user_type_id === 0|| userData?.belongs_to === "B2C_MAGAGER")) && <Col lg={6}>
                                <div className="form-floating">
                                    <Form.Select
                                        name="domain_name"
                                        onChange={handleInputChange}
                                        value={userPayload.domain_name || ''}
                                        isInvalid={!!errors.domain_name}
                                    >
                                        <option value="">Choose...</option>
                                        {userData?.domain?.map((domain: any, index: number) => (
                                            <option key={index} value={domain.domain_name}>
                                                {domain.host_name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Label>Domain List</Form.Label>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.domain_name}
                                    </Form.Control.Feedback>
                                </div>
                            </Col>
                        }

                        {(type !== "user"  && Number(typeId) !==2  ) ? <>
                            <Col lg={6}>
                                <div className="form-floating">
                                    <Form.Select
                                        name="Client Level"
                                        onChange={handleSelectClient}
                                        isInvalid={!!errors.user_type_id}

                                    >
                                        <option value="">Choose...</option>
                                        {(userTypeId > 8 || userTypeId === 0) && <option value={8} >White Label</option>}
                                        {(userTypeId > 7 || userTypeId === 0) && <option value={7} >Sub Admin</option>}
                                        {(userTypeId > 6 || userTypeId === 0) && <option value={6} >Hyper</option>}
                                        {(userTypeId > 5 || userTypeId === 0) && <option value={5} >Senior Super</option>}
                                        {(userTypeId > 4 || userTypeId === 0) && <option value={4} >Super</option>}
                                        {(userTypeId > 3 || userTypeId === 0) && <option value={3} >Master</option>}
                                        {(userTypeId > 2 || userTypeId === 0) && <option value={2} >Dealer</option>}
                                    </Form.Select>
                                    <Form.Label>Client Level</Form.Label>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.user_type_id}
                                    </Form.Control.Feedback>
                                </div>
                            </Col>

                        </>:
                        <>
                        {type !== "user" && Number(typeId) !==2 && <Col lg={6}>
                                <div className="form-floating">
                                    <Form.Select
                                        name="Client Level"
                                        onChange={handleSelectClient}
                                        isInvalid={!!errors.user_type_id}

                                    >
                                        <option value="">Choose...</option>
                                        {Number(userData?.user_type_id) > 8 && <option value={8} >White Label</option>}
                                        {Number(userData?.user_type_id) > 7 && <option value={7} >Sub Admin</option>}
                                        {Number(userData?.user_type_id) > 6 && <option value={6} >Hyper</option>}
                                        {Number(userData?.user_type_id) > 5 && <option value={5} >Senior Super</option>}
                                        {Number(userData?.user_type_id) > 4 && <option value={4} >Super</option>}
                                        {Number(userData?.user_type_id) > 3 && <option value={3} >Master</option>}
                                        {Number(userData?.user_type_id) > 2 && <option value={2} >Dealer</option>}
                                    </Form.Select>
                                    <Form.Label>Client Level</Form.Label>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.user_type_id}
                                    </Form.Control.Feedback>
                                </div>
                            </Col>}
                        </>
                        }
                        <Col lg={6}>
                            <div className="form-floating">
                                <Form.Select
                                    name="point"
                                    onChange={handleInputChange}
                                    value={1}

                                    isInvalid={!!errors.point}
                                >

                                    <option value="1">1</option>

                                </Form.Select>
                                <Form.Label>Point</Form.Label>
                                <Form.Control.Feedback type="invalid">
                                    {errors.point}
                                </Form.Control.Feedback>
                            </div>
                        </Col>

                        <Col lg={6}>
                            <div className="form-floating">
                                <Form.Control
                                    name="exposure_limit"
                                    type="number"
                                    placeholder="Exposure Limit"
                                    onChange={handleInputChange}
                                    value={-1}
                                    readOnly
                                    isInvalid={!!errors.exposure_limit}
                                />
                                <Form.Label>Exposure Limit</Form.Label>
                                <Form.Control.Feedback type="invalid">
                                    {errors.exposure_limit}
                                </Form.Control.Feedback>
                            </div>
                        </Col>

                        {
                            userPayload?.belongs_to !== "CHIP_SUMMARY" && type !== "user" && userType !== "2" ? <>
                                {type !== "b2c" && <Col lg={6}>
                                    <div className="form-check me-3">
                                        <Form.Check
                                            type="checkbox"
                                            id="BelongToUkraine"
                                            name="belongs_to_credit_reference"
                                            checked={userPayload.belongs_to_credit_reference === 1 && userPayload.user_type_id === "8"}
                                            onChange={handleInputChange}
                                            onKeyDown={(e) => {
                                                if (e.key === '-' || e.key === 'e' || e.key === '+' || e.key === 'E' || e.key === '.') {
                                                    e.preventDefault();
                                                }
                                            }}
                                        />
                                        <Form.Label className="form-check-label" htmlFor="BelongToUkraine">
                                            Belong To Ukraine
                                        </Form.Label>
                                    </div>
                                </Col>}


                                <Col lg={6}>
                                    <div className="form-check me-3">
                                        <Form.Check
                                            type="checkbox"
                                            id="BelongToB2C"
                                            name="belongs_to_credit_reference"
                                            checked={userPayload.belongs_to_credit_reference === 1 && userPayload?.user_type_id === "0"}
                                            onChange={handleInputChange}
                                        />
                                        <Form.Label className="form-check-label" htmlFor="BelongToB2C">
                                            Belong To {type === "b2c" ? "Credit Reference" : "B2C"}
                                        </Form.Label>
                                    </div>
                                </Col>
                                {
                                    (type === "uk" || type === "b2c") && <Col lg={6}>
                                        <div className="form-floating">
                                            <Form.Control
                                                name="credit_reference"
                                                type="number"
                                                placeholder="credit reference"
                                                onChange={handleInputChange}
                                                value={userPayload.credit_reference || ''}
                                                isInvalid={!!errors.credit_reference}
                                                onKeyDown={(e) => {
                                                    if (e.key === '-' || e.key === 'e' || e.key === '+' || e.key === 'E' || e.key === '.') {
                                                        e.preventDefault();
                                                    }
                                                }}
                                            />
                                            <Form.Label>Credit Reference</Form.Label>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.credit_reference}
                                            </Form.Control.Feedback>
                                        </div>
                                    </Col>
                                }

                            </> : ""
                        }

                        {
                            demo && <Col lg={6}>
                                <div className="form-floating">
                                    <Form.Check
                                        type="checkbox"
                                        id="BelongToB2C"
                                        name="belongs_to_credit_reference"
                                        checked={demoUser}
                                        onChange={(e) => setDemoUser(e.target.checked)}
                                    />
                                    <Form.Label className="form-check-label" htmlFor="BelongToB2C">
                                        Demo
                                    </Form.Label>
                                </div>
                            </Col>
                        }
                    </Row>
                </Form>
            </Card.Body>
        </Card>
    );
};

export default PersonalDetails;
