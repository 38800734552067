import BreadCrumb from "Common/BreadCrumb";
import Loader from "Common/Loader";
import FancyResultTable from "Common/Tables/FancyResultTable";
import { useEffect, useState } from "react";
import { Container, Button, Card, Form, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { settingServices } from "Utils/setting/services";

const FancyResult = () => {
  const [showFilter, setFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [fancyResult, setFancyResult] = useState<fancyResultRes[]>([]);
  const [limit, setLimit] = useState(50);
  const [page, setPage] = useState<number>(1);
  const [eventListData, setEventListData] = useState<LsitResponse>();
  const [tempSport, setTempSport] = useState<any>(null);
  const [tempSeries, setTempSeries] = useState<any>(null);
  const [tempMatch, setTempMatch] = useState<any>(null);
  const [tempMarket, setTempMarket] = useState<any>(null);
  const [customSelectSeries, setCustomSelectSeries] = useState<any[]>([]);
  const [customSelectMatch, setCustomSelectMatch] = useState<any[]>([]);
  const [customSelectMarket, setCustomSelectMarket] = useState<any[]>([]);

  const [listTotal, setListTotal] = useState<ProfitLossTotal>({
    total: 1,
    page: 1,
  });

  const getEventList = async (series: any, match: any, market: any) => {
    const searchPayload: any = {};
    searchPayload.sport_id = "4";
    if (series) searchPayload.series_id = series?.value;
    if (match) searchPayload.match_id = match?.value;
    if (market) searchPayload.market_id = market?.value;
    const { response } = await settingServices.getEventList({
      type: "openBets",
      search: searchPayload,
    });
    setIsLoading(false);
    setEventListData(response?.data[0]);
  };
  useEffect(() => {
    if (eventListData?.sports) {
      
      const series = eventListData.series.map((series) => ({
        value: series.series_id,
        label: series.series_name,
      }));
      const match = eventListData.matches.map((match) => ({
        value: match.match_id,
        label: match.match_name,
      }));
      const market = eventListData?.events_m_f?.map((market) => ({
        value: market.event_id,
        label: market.event_name,
      }));
      setCustomSelectSeries(series);
      setCustomSelectMatch(match);
      market && setCustomSelectMarket(market);
    }
  }, [eventListData]);

  const handleSeriesChange = (selectedOption: any) => {
    setTempSeries(selectedOption);
    setTempMatch(null);
    setTempMarket(null);
    setPage(1);
    setIsLoading(true)
    getEventList(selectedOption, null, null);
    getFancyResult(tempSport, selectedOption, null, null, limit, 1);
  };
  const handleMatchChange = (selectedOption: any) => {
    setTempMatch(selectedOption);
    setTempMarket(null);
    setPage(1);
    setIsLoading(true)
    getEventList(tempSeries, selectedOption, null);
    getFancyResult(tempSport, tempSeries, selectedOption, null, limit, 1);
  };

  const handleMarketChange = (selectedOption: any) => {
    setTempMarket(selectedOption);
    setPage(1);
    setIsLoading(true)
    getEventList(tempSeries, tempMatch, selectedOption);
    getFancyResult(tempSport, tempSeries, tempMatch, selectedOption, limit, 1);
  };

  const getFancyResult = async (
    sport: any,
    series: any,
    match: any,
    market: any,
    userLimit?: number,
    userPage?: number,
    isBeep?: boolean
  ) => {
    const searchPayload: any = {};
    searchPayload.is_result_declared = 0;
    if (sport) searchPayload.sport_id = sport?.value;
    if (series) searchPayload.series_id = series?.value;
    if (match) searchPayload.match_id = match?.value;
    if (market) searchPayload.fancy_id = market?.value;
    let page_no = page;
    if (userPage) page_no = userPage;
    const { response } = await settingServices.getFancyResult({
      limit: limit,
      page: page_no,
      search: searchPayload,
    });
    setIsLoading(false)
    setFancyResult(response?.data?.data);
    setListTotal({
      total: response?.data?.metadata[0]
        ? response?.data?.metadata[0]?.total / (userLimit || limit)
        : 1,
      page: response?.data?.metadata[0] ? response?.data?.metadata[0]?.page : 0,
    });

    let newData = response?.data?.data;

    const oldBetIds = new Set(fancyResult.map((item) => item._id));
    const newBetIdsNotInOld = newData.filter(
      (item: fancyResultRes) => !oldBetIds.has(item._id)
    );
    if (newBetIdsNotInOld?.length > 0 && isBeep) {
      beepOnce();
    }
  };

  const beepOnce = () => {
    const audio = new Audio("/beep/beep.mp3");
    audio.play();
  };

  useEffect(() => {
    getEventList(null, null, null);
    // eslint-disable-next-line
  }, [limit]);
  useEffect(() => {
    getFancyResult(null, null, null, null);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      getFancyResult(
        tempSport,
        tempSeries,
        tempMatch,
        tempMarket,
        limit,
        page,
        true
      );
    }, 30000);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line
  }, [tempSport, tempSeries, tempMatch, tempMarket, limit, page, fancyResult]);

  const handleRefresh = () => {
    setIsLoading(true)
    getEventList(tempSeries, tempMatch, tempMarket);
    getFancyResult(
      tempSport,
      tempSeries,
      tempMatch,
      tempMarket,
      limit,
      page,
      true
    );
  };

  const handleSubmit = () => {
    setIsLoading(true)
    setPage(1);
    getEventList(tempSeries, tempMatch, tempMarket);
    getFancyResult(tempSport, tempSeries, tempMatch, tempMarket, limit, 1);
  };
  const handleClear = () => {
    setIsLoading(true)
    setTempSport(null);
    setTempSeries(null);
    setTempMatch(null);
    setTempMarket(null);
    setPage(1);
    getFancyResult(null, null, null, null, limit, 1);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <div className="position-relative mb-2 d-flex justify-content-between align-items-center">
          <BreadCrumb title="Fancy Result" pageTitle="Dashboard" back />
          <div className="position-relative mb-2 d-flex justify-content-between align-items-center">
            <Button
              variant="outline-primary"
              onClick={handleRefresh}
              className="me-2 btn-sm"
              style={{ height: "38px" }}
            >
              <i className="fs-lg align-middle ri-refresh-line"></i>
            </Button>
            <Button
              variant="subtle-dark"
              onClick={() => setFilter(!showFilter)}
            >
              <i className="fs-lg align-middle ri-filter-3-line"></i> Filter
            </Button>
          </div>
        </div>

        {showFilter ? (
          <Card>
            <Card.Body>
              <Form>
                <Row className="gy-3 align-items-end">
                  <Col lg={9}>
                    <Row className="gap-3 gap-sm-0">
                      <Col lg={3}>
                        <Form.Label>Search Series Name</Form.Label>
                        <Select
                          isClearable
                          options={customSelectSeries}
                          onChange={handleSeriesChange}
                          className="customSelect"
                          value={tempSeries}
                        />
                      </Col>
                      <Col lg={3}>
                        <Form.Label>Search Match Name</Form.Label>
                        <Select
                          isClearable
                          options={customSelectMatch}
                          onChange={handleMatchChange}
                          className="customSelect"
                          value={tempMatch}
                        />
                      </Col>
                      <Col lg={3}>
                        <Form.Label>Search Market Name</Form.Label>
                        <Select
                          isClearable
                          options={customSelectMarket}
                          onChange={handleMarketChange}
                          className="customSelect"
                          value={tempMarket}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={3}>
                    <Button
                      onClick={handleSubmit}
                      className="me-2 btn-sm"
                      style={{ height: "38px" }}
                    >
                      <i className="fs-lg align-middle ri-filter-line"></i>{" "}
                      Submit
                    </Button>
                    <Button
                      variant="outline-primary"
                      onClick={handleClear}
                      className="me-2 btn-sm"
                      style={{ height: "38px" }}
                    >
                      <i className="fs-lg align-middle ri-refresh-line"></i>{" "}
                      Clear
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        ) : null}

        <Card>
          <Card.Body>
            <FancyResultTable
              listTotal={listTotal}
              page={page}
              limit={limit}
              fancyResult={fancyResult}
              getFancyResult={getFancyResult}
              setPage={setPage}
              setLimit={setLimit}
              setIsLoading={setIsLoading}
            />
          </Card.Body>
        </Card>
        {isLoading && <Loader />}
      </Container>
    </div>
  );
};

export default FancyResult;
