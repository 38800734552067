import  { useState, useEffect } from 'react';
import './Sidebar.scss'; // Assuming you have a CSS file for sidebar styles

const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);  // Sidebar collapsed state
  const [isMobile, setIsMobile] = useState(false);       // Detect if it's mobile/tablet view

  // Function to toggle the sidebar collapse
  const handleSidebarToggle = () => {
    if (isMobile) {
      setIsCollapsed(false);
    }
  };

  // Detect screen width and update `isMobile` state
  const checkScreenSize = () => {
    const mobileWidth = 1024; // Define the max width for mobile/tablet
    setIsMobile(window.innerWidth <= mobileWidth);
  };

  useEffect(() => {
    // Check screen size on load
    checkScreenSize();

    // Add a resize listener to detect screen changes
    window.addEventListener('resize', checkScreenSize);

    // Clean up event listener
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  return (
    <div
      className={`sidebar ${isCollapsed ? 'collapsed' : 'expanded'}`} // Apply styles based on state
      onMouseEnter={() => !isMobile && setIsCollapsed(false)} // Expand on hover for non-mobile
      onMouseLeave={() => !isMobile && setIsCollapsed(true)}  // Collapse on mouse leave for non-mobile
    >
      <ul>
        <li onClick={handleSidebarToggle}>Menu 1</li>
        <li onClick={handleSidebarToggle}>Menu 2</li>
        <li onClick={handleSidebarToggle}>Menu 3</li>
        <li onClick={handleSidebarToggle}>Menu 4</li>
      </ul>
    </div>
  );
};

export default Sidebar;