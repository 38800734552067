import BreadCrumb from "Common/BreadCrumb";
import { ListPagination } from "Common/ListPagination";
import Loader from "Common/Loader";
import TableContainer from "Common/TableContainer";
import TableEntry from "Common/TableEntry";
import { useEffect, useMemo, useState } from "react";
import { Container, Card, Modal, Button } from "react-bootstrap";
import { authServices } from "Utils/auth/services";
import { settingServices } from "Utils/setting/services";

const UserMobileData = () => {
  const [userData, setUserData] = useState<userNameResponse[]>([])
  const [limit, setLimit] = useState<number>(100);
  const [page, setPage] = useState<number>(1);
  const [totalData, setTotalData] = useState<number>(0);
  const [formValuesFilter, setFormValuesFilter] = useState<any>({});
  const [domainList, setDomainList] = useState<DomainListPayload[]>([]);
  const [domainId, setDomainId] = useState<string>("");
  const [userNameData, setUserNameData] = useState<any[]>([]);
  const [user_name, setUser_name] = useState("");
  const [agentList, setAgentList] = useState<CasinoUserAgent[]>([]);
  const [showList, setList] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userName, setUserName] = useState<string>("");


  const getAllWebsite = async () => {
    const { response } = await authServices.getAllWebsite();
    const options = response?.data.map((user: any) => ({
      value: user?._id,
      label: user.host_name,
    }));
    setDomainList(options);
  };

  

  const getUserbyUserName = async (user_name: string) => {
    const { response } = await settingServices.getUserbyUserName({
      user_name
    });
    const options = response?.data.map((user: any) => ({
      value: user?.user_name,
      label: user?.user_name,
    }));
    setUserNameData(options)
  };

  const handleSelectUser = (selectedOption: any) => {
    if (selectedOption) {
      setUser_name(selectedOption.value);
    }
  };

  const handleUser = (inputValue: string) => {
    if (inputValue?.length > 3) {
      getUserbyUserName(inputValue);
    }
  };

  useEffect(() => {
    getAllWebsite();
  }, []);


  const handleDomain = (selectedOption: any) => {
    const selectedValue = selectedOption ? selectedOption.value : "";
    setDomainId(selectedValue);

  };

  const getUserMobileName = async () => {
    const { response } = await settingServices.getUserMobileName({
      limit,
      page: page
    })
    setIsLoading(false);
    setUserData(response?.data?.data);
    setTotalData(response?.data?.metadata?.total || 0)
  }

  useEffect(() => {
    getUserMobileName();
    // eslint-disable-next-line
  }, [limit, page])


  const getShowAgent = async (user_id: string) => {
    const { response } = await settingServices.getShowAgent({ user_id });
    setIsLoading(false)
    if (response?.data) {
      setAgentList(response?.data?.agents);
    }
  };


  const toggleList = (id: string, name: string) => {
    setIsLoading(true);
    getShowAgent(id);
    setUserName(name);
    setList(!showList);
  };

  const columns = useMemo(
    () => [
      {
        header: "No.",
        accessorKey: "sNo",
        enableColumnFilter: true,
        cell: (cell: any) => {
          return ((page - 1) * limit) + cell?.row?.index + 1
        }
      },
      {
        header: "Domain",
        accessorKey: "domain_name",
        enableColumnFilter: true,
      },
      {
        header: "User Name",
        accessorKey: "user_name",
        enableColumnFilter: true,
        cell: (cell: any) => {
          return <div className="cursor-pointer" onClick={()=>toggleList(cell.row.original._id,cell.row.original.name )}>{cell.row.original.user_name} [{cell.row.original.name}]</div>
        }
      },
      {
        header: "Mobile",
        accessorKey: "mobileNumber",
        enableColumnFilter: true,
        cell: (cell: any) => {
          const mobileNumber = cell.row.original.mobile;
          const whatsappLink = `https://wa.me/${mobileNumber}`;
          return (<div>
            <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
              {mobileNumber}
            </a>
          </div>)
        }
      },
    ],
    // eslint-disable-next-line
    [userData, page, limit]
  );

  return (
    <div className="page-content">
      <Container fluid>
        <div className="position-relative mb-2 d-flex justify-content-between align-items-center">
          <BreadCrumb title="User Mobile Data" pageTitle="Dashboard" back />
        </div>

        <Card>
          <Card.Body>
            <TableContainer
              columns={columns || []}
              data={userData || []}
              isBordered={false}
              customPageSize={limit}
              isPagination={false}
              tableClass="table-centered align-middle table-nowrap table-striped mb-3"
              theadClass="text-muted table-light"
              SearchPlaceholder="Search Products..."
              setLimit={setLimit} isFilter={true}
              setFormValuesFilter={setFormValuesFilter}
              formValuesFilter={formValuesFilter}
              domainList={domainList}
              domainId={domainId}
              handleDomain={handleDomain}
              userNameData={userNameData}
              userName={user_name}
              handleSelectUser={handleSelectUser}
              setUser_name={setUser_name}
              handleUser={handleUser}
              sorting={true}
            />
            <nav aria-label="Page navigation" className="px-3 d-flex flex-column flex-sm-row align-items-center">
              <TableEntry
                limit={limit}
                handlelimitFilter={(value: number) => setLimit(value)}
                minLimit={100}
              />
              <ListPagination
                listTotal={{
                  total: totalData / limit,
                  page: page,
                }}
                handlePageFilter={(value: number) => setPage(value)}
              />
            </nav>

          </Card.Body>
        </Card>
        {isLoading && <Loader />}
      </Container>
      <Modal show={showList} onHide={() => setList(false)} className="zoomIn" scrollable>
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <div className="d-flex align-items-center flex-wrap">
            Parent List of {" "}
            <span className="text-muted fs-xs fw-normal mx-0 mx-sm-2 fs-xs">({userName})</span>
          </div>
          <Button variant="light btn-sm" onClick={() => setList(false)}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          {agentList?.length !== 0 ? agentList?.map((item) => {
            return (
              <p>{item.name}({item.user_name})</p>
            );
          }) :
          <p className="text-center p-2 fw-bold fs-6">
            User or it's Details not found!
          </p>}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UserMobileData;