import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import BreadCrumb from 'Common/BreadCrumb';
import { settingServices } from 'Utils/setting/services';
import { useParams } from 'react-router-dom';
import CasinoBets from 'pages/Reports/CasinoBets';
import Loader from 'Common/Loader';



const Casino = () => {
    const [casinoData, setCasinoData] = useState<string>("")
    const [isLoading, setIsLoading] = useState(true);
    const { id, name } = useParams();
    const getLaunchUrl = async (game_id: string) => {
        const { response } = await settingServices.getLaunchUrl({
            game_id,
            device_type: "mobile"
        })
        if (response) {
            setIsLoading(false);
            setCasinoData(response?.launchUrl)
        }
    }
    useEffect(() => {
        if (id) {
            getLaunchUrl(id);
        }
    }, [id])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb back={true} title="Casino" pageTitle={name || ""} />

                    <Row>
                        <Col md={6}>
                            <div style={{ height: "80vh" }}>
                                <iframe width="100%" height="100%" src={casinoData} title="casino_url" />
                            </div>
                        </Col>
                        <Col md={6}><CasinoBets gameId={id} filter={false} activeTab={"CasinoBets"} /></Col>
                    </Row>



                   {isLoading && <Loader />}
                </Container>
            </div>

        </React.Fragment>
    );
};

export default Casino;
