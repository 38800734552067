import BreadCrumb from "Common/BreadCrumb";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { Container, Card, Col, Form, Row, Button } from "react-bootstrap";
import _, { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { authServices } from "Utils/auth/services";
import { useParams } from "react-router-dom";
import { currentDateTime, getStartTimeOfDay } from "Utils/datefilter";
import SportsWisePLTable from "Common/Tables/SportWisePLTable";
import Loader from "Common/Loader";
import { generateOptions } from "Utils/searchFilter";

const SportWisePL = () => {
  const { marketid, type, userid, isCasino, start, end } = useParams();
  const [usersData, setUsersData] = useState<SearchUsersListData[]>([]);
  const currentDate = new Date();
  const sevenDaysAgo = new Date(currentDate.setDate(currentDate.getDate() - 7));
  const [selectedUser, setSelectedUser] = useState<string>("");
  const [inputValue, setInputValue] = useState("");
  const [showFilter, setFilter] = useState(true);
  const [startDate, setStartDate] = useState<string | Date>(
    start ? new Date(start) : getStartTimeOfDay(sevenDaysAgo)
  );
  const [endDate, setEndDate] = useState<string | Date>(end ? new Date(end) : currentDateTime());
  const [sports, setSports] = useState<SportsList[]>([]);
  const [sportsData, setSportsData] = useState<SportWisePlList[]>([]);
  const [parentId, setParentId] = useState<string>("null");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const userId = userid && userid !== 'null' ? userid : localStorage.getItem("userId");

  const geOpenBetsList = async (filters: any = {}) => {
    setIsLoading(true);
    const { response } = await authServices.getSportWisePL({
      user_id: userId,
      ...filters,
    });
    setIsLoading(false);
    if (response?.status) {
      let userPlList = response.data.users;
      userPlList = _.orderBy(
        userPlList,
        ["user_type_id", "user_name"],
        ["desc", "asc"]
      );
      let gameList = Object.keys(response.data.sports).map((data) => ({
        name: data,
        lower_name: "",
        total: 0,
      }));
      let a = {
        name: "Commission",
        lower_name: "",
        total: 0,
      };
      let b = {
        name: "Total With Commission",
        lower_name: "",
        total: 0,
      };
      let c = {
        name: "Total",
        lower_name: "",
        total: 0,
      };
      gameList.push(c);
      gameList.push(a);
      gameList.push(b);
      for (let i = 0; i < userPlList.length; i++) {
        for (let j = 0; j < gameList.length; j++) {
          gameList[j].lower_name = gameList[j].name.toLowerCase();
          gameList[j].lower_name = gameList[j].lower_name.replace(/ /g, "_");
          if (userPlList[i][gameList[j].name.toLowerCase().replace(/ /g, "_")] === undefined) {
            userPlList[i][
              gameList[j].name.toLowerCase().replace(/ /g, "_")
            ] = 0;
          }
        }
      }
      for (let i = 0; i < userPlList.length; i++) {
        userPlList[i].total_with_commission =
          userPlList[i].total + userPlList[i].commission;
      }
      for (let i = 0; i < gameList.length; i++) {
        gameList[i].total = 0;
        for (let j = 0; j < userPlList.length; j++) {
          if (
            userPlList[j][gameList[i].lower_name] === undefined ||
            userPlList[j][gameList[i].lower_name] === null ||
            userPlList[j][gameList[i].lower_name] === ""
          ) {
            gameList[i].total = gameList[i].total + 0;
          } else {
            gameList[i].total =
              gameList[i].total + userPlList[j][gameList[i].lower_name];
          }
        }
      }

      setSportsData(userPlList);
      setParentId(response?.parent_id);
      setSports(gameList);
    }
  };

  useEffect(() => {
    let payload = {
      event_id: marketid,
      ...(type !== '1' && { type: type }),
    };
    geOpenBetsList({
      from_date: startDate,
      to_date: endDate,
      search: payload,
    });
    // eslint-disable-next-line
  }, []);

  const handlefilterSubmit = () => {
    let filterData = {
      event_id: marketid,
      ...(type !== '1' && { type: type }),
    };

    geOpenBetsList({
      from_date: startDate,
      to_date: endDate,
      search: filterData,
    });
  };

  const handleClearSelection = () => {
    let payload = {
      event_id: marketid,
      ...(type !== '1' && { type: type }),
    };
    geOpenBetsList({
      from_date: getStartTimeOfDay(sevenDaysAgo),
      to_date: currentDateTime(),
      search: payload,
    });
    setStartDate(getStartTimeOfDay(sevenDaysAgo));
    setEndDate(currentDateTime());
  };

  const handleUserDetail = (user: string) => {
    let payload = {
      event_id: marketid,
      ...(type !== '1' && { type: type }),
    };
    geOpenBetsList({
      from_date: getStartTimeOfDay(sevenDaysAgo),
      to_date: currentDateTime(),
      user_id: user && user !== "null" ? user : userId,
      search: payload,
    });
    setStartDate(getStartTimeOfDay(sevenDaysAgo));
    setEndDate(currentDateTime());
  };

 
  const getUsresList = async (userName: string) => {
    const { response } = await authServices.getUserList({
      user_name: userName,
    });
    if (response?.data) {
      setUsersData(response?.data);
    } else {
      setUsersData([]);
    }
  };

  const fetchOptions = debounce(async (input: string) => {
    if (input.length >= 3) {
      getUsresList(input);
    }
  }, 300);

  useEffect(() => {
    fetchOptions(inputValue);
    // eslint-disable-next-line
  }, [inputValue]);


  const userOptions = useMemo(
    () => generateOptions(usersData || [], "_id", "user_name"),
    [usersData]
  );


  // useEffect(()=>{
  //   let filterData = {
  //     event_id: marketid,
  //     ...(type != '1' && { type: type }),
  //     userId:selectedUser
  //   };

  //   geOpenBetsList({
  //     from_date: startDate,
  //     to_date: endDate,
  //     userId:selectedUser,
  //     search: filterData,
  //   });
  // },[selectedUser])


  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div
            className="position-relative mb-2 d-flex justify-content-between align-items-center"
            style={{ minHeight: "43px" }}
          >
            <BreadCrumb title={"View Sport Wise PL"} pageTitle="Dashboard" back />
            <Button variant="subtle-dark" onClick={() => setFilter(!showFilter)}>
              <i className="fs-lg align-middle ri-filter-3-line"></i> Filter
            </Button>
          </div>
          <>
            {showFilter ? (
              <Card>
                <Card.Body>
                  <Form>
                    <Row className="gy-3 align-items-end">
                      <Col lg={3}>
                        <Form.Label>Start Date</Form.Label>
                        <Flatpickr
                          className="form-control"
                          pl
                          options={{
                            enableTime: true,
                            dateFormat: "Y-m-d H:i:s",
                            defaultDate: [startDate],
                            maxDate: endDate,
                          }}
                          onChange={([date]: [Date]) => {
                            setStartDate(new Date(date));
                          }}
                        />
                      </Col>
                      <Col lg={3}>
                        <Form.Label>End Date</Form.Label>
                        <Flatpickr
                          className="form-control"
                          pl
                          options={{
                            enableTime: true,
                            dateFormat: "Y-m-d H:i:s",
                            defaultDate: [endDate],
                            maxDate: new Date(),
                          }}
                          onChange={([date]: [Date]) => {
                            setEndDate(new Date(date));
                          }}
                        />
                      </Col>
                      <Col lg={3}>
                        <Form.Label>Search User Name</Form.Label>
                        <Select
                          isClearable
                          options={userOptions || []}
                          className="customSelect"
                          value={
                            userOptions?.find(
                              (option: any) => option.value === selectedUser
                            ) || null
                          }
                          onInputChange={(input: any) => setInputValue(input)}
                          onChange={(e: any) => {
                            if (e) {
                              setSelectedUser(e.value);
                            } else {
                              setSelectedUser("");
                            }
                          }}
                        />
                      </Col>

                      <Col lg={3}>
                        <Button
                          className="me-2 btn-sm"
                          style={{ height: "38px" }}
                          onClick={() => handlefilterSubmit()}
                        >
                          <i className="fs-lg align-middle ri-filter-line"></i>{" "}
                          Submit
                        </Button>
                        <Button
                          variant="outline-primary"
                          className="btn-sm"
                          style={{ height: "38px" }}
                          onClick={() => handleClearSelection()}
                        >
                          <i className="fs-lg align-middle ri-refresh-line"></i>{" "}
                          Clear
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            ) : null}
            <Card>
              <Card.Body>
                <SportsWisePLTable
                  sportsPLData={sportsData}
                  sports={sports}
                  parentId={parentId}
                  handleUserDetail={handleUserDetail}
                  isEvent={true}
                  isCasino={isCasino || '1'}
                />
              </Card.Body>
            </Card>
          </>
        </Container>
      </div>
      {isLoading && <Loader />}
    </>
  );
};

export default SportWisePL;
