import React from 'react';
import { Container } from 'react-bootstrap';
import SeriesByData from 'Common/SeriesByData';


const SeriesData = () => {


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <SeriesByData />

                </Container>
            </div>

        </React.Fragment>
    );
};

export default SeriesData;
