
import { Badge, Card, Col, Dropdown, Table } from "react-bootstrap";

import { useState } from "react";

interface Props {
    fetchData: (type: string, days: number, query: string[]) => Promise<void>,
    data: any,

}


const TotalPL = ({fetchData,data }:Props) => {
    const [showModal, setModal] = useState(false)
    const ToggleModal = () => {
        setModal(!showModal)
    }
    const [selectName, setSelectName] = useState("Select")
    const handleDate = (days: number, name: string) => {
        setSelectName(name);
        
        fetchData("plQuery",days, ["plQuery"])
    }

    return (
        <Col xxl={4}>
            <Card className="card-height-100">
                <Card.Header className="d-flex border-bottom pb-3">
                    <h5 className="card-title mb-0 flex-grow-1">Total PL<Badge className="cursor-pointer bg-body-secondary border border-primary text-primary ms-1" onClick={ToggleModal}>View All</Badge></h5>
                    <div className="flex-shrink-0">
                        <Dropdown className="card-header-dropdown sortble-dropdown cursor-pointer">
                            <Dropdown.Toggle as='a' className="text-reset arrow-none mb-0">
                                <span className="text-muted dropdown-title">{selectName}</span> <i className="mdi mdi-chevron-down ms-1"></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="dropdown-menu-end">
                                <Dropdown.Item onClick={() => handleDate(1, "Today")}>Today</Dropdown.Item>
                                <Dropdown.Item onClick={() => handleDate(7, "This Week")}>This Week</Dropdown.Item>
                                <Dropdown.Item onClick={() => handleDate(30, "This Month")}>This Month</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </Card.Header>
                <div className="card-body">
                    <div className="table-resposive">
                        <Table>
                            <thead className="table-light">
                                <tr>
                                    <th>Sport PL</th>
                                    <th>Casino PL</th>
                                    <th>PL</th>
                                </tr>
                            </thead>
                            <tbody>
                                    {data?.plQuery?.pl_data?.length !== 0 ? data?.plQuery?.pl_data?.slice(0, 10)?.map((items: any) => {
                                        return (
                                            <tr>
                                                <td className={items?.sport_pl < 0?"text-danger":""}>{items?.sport_pl}</td>
                                                <td className={items?.casino_pl < 0?"text-danger":""}>{items?.casino_pl}</td>
                                                <td className={items?.total_pl < 0?"text-danger":""}>{items?.total_pl}</td>
                                                
                                            </tr>
                                        )
                                    }) : <tr>
                                        <td colSpan={4} className="text-center">No Data Found!</td>
                                    </tr>}
                                </tbody>
                        </Table>
                    </div>
                </div>
            </Card>
        </Col>
    );
};

export default TotalPL;