import BreadCrumb from "Common/BreadCrumb";
import Loader from "Common/Loader";
import ResultRollbackFancyTable from "Common/Tables/ResultRollbackFancyTable";
import ResultRollbackTable from "Common/Tables/ResultRollbackTable";
import { FC, useEffect, useState } from "react";
import { Container, Button, Card, Form, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { settingServices } from "Utils/setting/services";
import snackbarUtil from "Utils/snackBarUtil";

interface Props {
  isMatch: boolean;
  type: string;
  name: string;
}

const MatchRollBack: FC<Props> = ({ isMatch, type, name }) => {
  const [showFilter, setFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [eventListData, setEventListData] = useState<LsitResponse>();
  const [customSelectOptions, setCustomSelectOptions] = useState<any[]>([]);
  const [customSelectSeries, setCustomSelectSeries] = useState<any[]>([]);
  const [customSelectMatch, setCustomSelectMatch] = useState<any[]>([]);
  const [customSelectMarket, setCustomSelectMarket] = useState<any[]>([]);
  const [tempSport, setTempSport] = useState<any>(null);
  const [tempSeries, setTempSeries] = useState<any>(null);
  const [tempMatch, setTempMatch] = useState<any>(null);
  const [tempMarket, setTempMarket] = useState<any>(null);
  const [abdFancy, setAbdFancy] = useState<any>(false);
  const [matchedRollBack, setMatchedRollBack] = useState<matchRollBackRes[]>(
    []
  );
  const [fancyResultData, setFancyResultData] = useState<any[]>([]);
  const [showRollback, setRollback] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(50);
  const [totalPage, setTotalPage] = useState<number>(1);

  const getEventList = async (
    sport?: any,
    series?: any,
    match?: any,
    market?: any
  ) => {
    const searchPayload: any = {};
    if (isMatch) {
      if (sport) searchPayload.sport_id = sport?.value;
    } else {
      searchPayload.sport_id = "4";
    }

    if (series) searchPayload.series_id = series?.value;
    if (match) searchPayload.match_id = match?.value;
    if (market) searchPayload.market_id = market?.value;

    const { response } = await settingServices.getEventList({
      type,
      search: searchPayload,
    });
    setIsLoading(false)
    setEventListData(response?.data[0]);
  };
  
  const getMatchRoolBack = async (
    sport?: any,
    series?: any,
    match?: any,
    market?: any,
    pageno?: number,
  ) => {
    const searchPayload: any = {};
    if (sport) searchPayload.sport_id = sport?.value;
    if (series) searchPayload.series_id = series?.value;
    if (match) searchPayload.match_id = match?.value;
    if (market) searchPayload.market_id = market?.value;
    let page = pageno ? pageno: pageNo;

    const { response } = await settingServices.getMatchRoolBack({
      limit: limit,
      page: page,
      search: searchPayload,
    });
    setIsLoading(false)
    setTotalPage(response?.data?.metadata?.[0]?.total);
    setMatchedRollBack(response?.data?.data);
  };

  const getFancyResult = async (
    sport?: any,
    series?: any,
    match?: any,
    market?: any,
    page_no?: number,
  ) => {
    const searchPayload: any = {};
    if (sport) searchPayload.sport_id = sport?.value;
    if (series) searchPayload.series_id = series?.value;
    if (match) searchPayload.match_id = match?.value;
    if (market) searchPayload.fancy_id = market?.value;
    let page = page_no ? page_no : pageNo;
    const { response } = await settingServices.getFancyResult({
      limit: limit,
      page: page,
      search: {
        is_result_declared: 1,
        ...searchPayload,
      },
    });
    setIsLoading(false)
    setTotalPage(response?.data?.metadata?.[0]?.total);
    setFancyResultData(response?.data?.data);
  };

  useEffect(() => {
    getEventList(tempSport, tempSeries, tempMatch, tempMarket);
    if (isMatch) {
      getMatchRoolBack(tempSport, tempSeries, tempMatch, tempMarket);
    } else {
      getFancyResult(tempSport, tempSeries, tempMatch, tempMarket);
    }
    // eslint-disable-next-line
  }, [isMatch, limit, pageNo]);

  useEffect(() => {
    if (eventListData?.sports) {
      const options = eventListData?.sports?.map((sport) => ({
        value: sport.sport_id,
        label: sport.sport_name,
      }));
      const series = eventListData?.series?.map((series) => ({
        value: series.series_id,
        label: series.series_name,
      }));
      const match = eventListData?.matches?.map((match) => ({
        value: match.match_id,
        label: match.match_name,
      }));
      const market = eventListData?.markets?.map((market) => ({
        value: market.market_id,
        label: market.market_name,
      }));
      const marketMf = eventListData?.events_m_f?.map((market) => ({
        value: market.event_id,
        label: market.event_name,
      }));
      if (isMatch) {
        setCustomSelectMarket(market);
      } else {
        if (marketMf) setCustomSelectMarket(marketMf);
      }
      setCustomSelectOptions(options);
      setCustomSelectSeries(series);
      setCustomSelectMatch(match);
    }
    // eslint-disable-next-line
  }, [eventListData]);

  const handleSportChange = (selectedOption: any) => {
    setIsLoading(true)
    setTempSport(selectedOption);
    setTempSeries(null);
    setTempMatch(null);
    setTempMarket(null);
    setPageNo(1);
    getEventList(selectedOption, null, null, null);
    if (isMatch) {
      getMatchRoolBack(selectedOption, null, null, null, 1);
    } else {
      getFancyResult(selectedOption, null, null, null, 1);
    }
  };

  const handleSeriesChange = (selectedOption: any) => {
    setIsLoading(true)
    setTempSeries(selectedOption);
    setTempMatch(null);
    setTempMarket(null);
    setPageNo(1);
    getEventList(tempSport, selectedOption, null, null);
    if (isMatch) {
      getMatchRoolBack(tempSport, selectedOption, null, null, 1);
    } else {
      getFancyResult(tempSport, selectedOption, null, null, 1);
    }
  };

  const handleMatchChange = (selectedOption: any) => {
    setIsLoading(true)
    setTempMatch(selectedOption);
    setTempMarket(null);
    setPageNo(1);
    getEventList(tempSport, tempSeries, selectedOption, null);
    if (isMatch) {
      getMatchRoolBack(tempSport, tempSeries, selectedOption, null, 1);
    } else {
      getFancyResult(tempSport, tempSeries, selectedOption, null, 1);
    }
  };

  const handleMarketChange = (selectedOption: any) => {
    setIsLoading(true)
    setTempMarket(selectedOption);
    setPageNo(1);
    getEventList(tempSport, tempSeries, tempMatch, selectedOption);
    if (isMatch) {
      getMatchRoolBack(tempSport, tempSeries, tempMatch, selectedOption, 1);
    } else {
      getFancyResult(tempSport, tempSeries, tempMatch, selectedOption, 1);
    }
  };

  const handleSubmit = () => {
    setIsLoading(true)
    setPageNo(1);
    getEventList(tempSport, tempSeries, tempMatch, tempMarket);
    if (isMatch) {
      getMatchRoolBack(tempSport, tempSeries, tempMatch, tempMarket, 1);
    } else {
      getFancyResult(tempSport, tempSeries, tempMatch, tempMarket, 1);
    }
  };

  const handleClear = () => {
    setIsLoading(true)
    setTempSport(null);
    setTempSeries(null);
    setTempMatch(null);
    setTempMarket(null);
    setPageNo(1);
    getEventList();
    if (isMatch) {
      getMatchRoolBack(null, null, null, null, 1);
    } else {
      getFancyResult(null, null, null, null, 1);
    }
  };

  const getOddsRollBack = async (market_id: string) => {
    const { response } = await settingServices.getOddsRollBack({ market_id });
    if (response) {
      if (response?.status) {
        setIsLoading(false)
        snackbarUtil.success(response?.msg);
        getMatchRoolBack();
        setRollback(false);
      } else {
        setIsLoading(false)
        snackbarUtil.error(response?.msg);
      }
    }
  };



  const getSessionAbd = async(fancy_id:string, rollback:number)=>{
    const {response} = await settingServices.getSessionAbd({
      fancy_id,
      rollback
    })
    if(response){
      if(response?.status){
        setIsLoading(false)
        snackbarUtil?.success(response?.msg);
        getFancyResult();
        setRollback(false);
      }else{
        setIsLoading(false)
        snackbarUtil.error(response?.msg)
      }
    }
  }

  const getSessionRollback = async (market_id: string) => {
    const { response } = await settingServices.getSessionRollback({
      fancy_id: market_id,
    });
    if (response) {
      if (response?.status) {
        snackbarUtil.success(response?.msg);
        setIsLoading(false)
        getFancyResult();
        setRollback(false);
      } else {
        snackbarUtil.error(response?.msg);
        setIsLoading(false)
      }
    }
  };

  const getOddsABD = async (market_id: string, rollback: number) => {
    const { response } = await settingServices.getOddsABD({
      market_id,
      rollback,
    });
    if (response) {
      if (response?.status) {
        setIsLoading(false)
        snackbarUtil.success(response?.msg);
        getMatchRoolBack();
        setRollback(false);
      } else {
        setIsLoading(false)
        snackbarUtil.error(response?.msg);
      }
    }
  };

  return (
    <div className="page-content">
      <Container fluid>
        <div className="position-relative mb-2 d-flex justify-content-between align-items-center">
          <BreadCrumb title={`${name} Rollback`} pageTitle="Dashboard" back />
          <Button variant="subtle-dark" onClick={() => setFilter(!showFilter)}>
            <i className="fs-lg align-middle ri-filter-3-line"></i> Filter
          </Button>
        </div>

        {showFilter ? (
          <Card>
            <Card.Body>
              <Form>
                <Row className="gy-3 align-items-end">
                  <Col lg={9}>
                    <Row className="gap-3 gap-sm-0">
                      {isMatch && (
                        <Col lg={3}>
                          <Form.Label>Search Sport Name</Form.Label>
                          <Select
                            isClearable
                            className="customSelect"
                            options={customSelectOptions || []}
                            onChange={handleSportChange}
                            value={tempSport}
                          />
                        </Col>
                      )}

                      <Col lg={3}>
                        <Form.Label>Search Series Name</Form.Label>
                        <Select
                          isClearable
                          className="customSelect"
                          options={customSelectSeries || []}
                          onChange={handleSeriesChange}
                          value={tempSeries}
                        />
                      </Col>
                      <Col lg={3}>
                        <Form.Label>Search Match Name</Form.Label>
                        <Select
                          isClearable
                          options={customSelectMatch || []}
                          onChange={handleMatchChange}
                          value={tempMatch}
                        />
                      </Col>
                      <Col lg={3}>
                        <Form.Label>Search Market Name</Form.Label>
                        <Select
                          isClearable
                          className="customSelect"
                          options={customSelectMarket || []}
                          onChange={handleMarketChange}
                          value={tempMarket}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={3}>
                    <Button
                      className="me-2 btn-sm"
                      onClick={handleSubmit}
                      style={{ height: "38px" }}
                    >
                      <i className="fs-lg align-middle ri-filter-line"></i>{" "}
                      Submit
                    </Button>
                    <Button
                      variant="outline-primary"
                      onClick={handleClear}
                      className="me-2 btn-sm"
                      style={{ height: "38px" }}
                    >
                      <i className="fs-lg align-middle ri-refresh-line"></i>{" "}
                      Clear
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        ) : null}

        <Card>
          <Card.Body>
            {isMatch ? (
              <ResultRollbackTable
                setRollback={setRollback}
                showRollback={showRollback}
                getOddsABD={getOddsABD}
                getOddsRollBack={getOddsRollBack}
                matchedRollBack={matchedRollBack}
                setLimit={setLimit}
                setPageNo={setPageNo}
                totalPage={totalPage}
                limit={limit}
                pageNo={pageNo}
                setAbdFancy={setAbdFancy}
                setIsLoading={setIsLoading}
              />
            ) : (
              <ResultRollbackFancyTable
                setRollback={setRollback}
                showRollback={showRollback}
                getOddsABD={getSessionAbd}
                getOddsRollBack={getSessionRollback}
                matchedRollBack={fancyResultData}
                setLimit={setLimit}
                setPageNo={setPageNo}
                totalPage={totalPage}
                limit={limit}
                pageNo={pageNo}
                setAbdFancy={setAbdFancy}
                abdFancy={abdFancy}
                setIsLoading={setIsLoading}
              />
            )}
          </Card.Body>
        </Card>
        {isLoading && <Loader />}
      </Container>
    </div>
  );
};

export default MatchRollBack;
