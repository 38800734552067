import TableContainer from "Common/TableContainer";
import moment from "moment";
import { useMemo, useState } from "react";
import { Button, Modal, Pagination, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { authServices } from "Utils/auth/services";

interface SportsWisePLTableProps {
  sportsPLData: SportWisePlList[];
  sports: SportsList[];
  parentId: string;
  handleUserDetail: (value: string) => void;
  isEvent: boolean;
  isCasino: string;
}

const SportsWisePLTable = ({
  sportsPLData,
  sports,
  parentId,
  handleUserDetail,
  isEvent,
  isCasino,
}: SportsWisePLTableProps) => {
  const { matchid, marketid, type } = useParams();
  const navigate = useNavigate();
  const [showList, setList] = useState(false);
  const [selectedUser, setSelectedUser] = useState<string>("");
  const [agentList, setAgentList] = useState<CasinoUserAgent[]>([]);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "asc" | "desc";
  } | null>(null);

  let userDetail = localStorage.getItem("adminDetails");
  let currentUser = JSON.parse(userDetail || "")?.parent_id;

  const toggleList = (name?: string) => {
    setAgentList([]);
    setList(!showList);
    setSelectedUser(name || "");
  };

  const getUserAgent = async (userId: string) => {
    const { response } = await authServices.getShowAgent({
      user_id: userId,
    });
    if (response?.data) {
      setAgentList(response?.data?.agents);
    }
  };

  const handleSort = (key: string) => {
    setSortConfig((prev) => {
      if (prev && prev.key === key && prev.direction === "asc") {
        return { key, direction: "desc" };
      }
      return { key, direction: "asc" };
    });
  };

  const sortedData = [...sportsPLData].sort((a, b) => {
    if (!sortConfig) return 0;

    const aValue = a[sortConfig.key as keyof typeof a];
    const bValue = b[sortConfig.key as keyof typeof b];

    // Check if the values are numbers or strings
    const isNumeric = !isNaN(Number(aValue)) && !isNaN(Number(bValue));

    if (isNumeric) {
      // Numeric comparison
      if (sortConfig.direction === "asc") {
        return Number(aValue) - Number(bValue);
      } else {
        return Number(bValue) - Number(aValue);
      }
    } else {
      // String comparison (case-insensitive)
      const aString = String(aValue).toLowerCase();
      const bString = String(bValue).toLowerCase();
      if (sortConfig.direction === "asc") {
        return aString.localeCompare(bString);
      } else {
        return bString.localeCompare(aString);
      }
    }
  });

  return (
    <>
      <div className="table-responsive">
        <Table className="table-centered align-middle table-nowrap table-striped">
          <thead className="table-light">
            <tr>
              <th>
                <div className="d-flex">
                  S. No.{" "}
                  <i
                    className={`ms-1 ri-arrow-up-down-line ${
                      sortConfig?.key != "index" && "text-muted"
                    }  fs-md align-middle cursor-pointer select-none  ${
                      sortConfig?.key === "index" ? "sticky-active" : "sticky"
                    }`}
                    onClick={() => handleSort("index")}
                  ></i>
                </div>
              </th>
              <th>
                <div className="d-flex">
                  Name{" "}
                  <i
                    className={`ms-1 ri-arrow-up-down-line ${
                      sortConfig?.key != "user_name" && "text-muted"
                    }  fs-md align-middle cursor-pointer select-none  ${
                      sortConfig?.key === "user_name"
                        ? "sticky-active"
                        : "sticky"
                    }`}
                    onClick={() => handleSort("user_name")}
                  ></i>
                </div>
              </th>
              {sports.map((key) => (
                <th key={key.name}>
                  <div className="d-flex">
                    {key.name}{" "}
                    <i
                      className={`ms-1 ri-arrow-up-down-line ${
                        sortConfig?.key != key.lower_name && "text-muted"
                      } fs-md align-middle cursor-pointer select-none ${
                        sortConfig?.key === key.lower_name
                          ? "sticky-active"
                          : "sticky"
                      }`}
                      onClick={() => handleSort(key.lower_name)}
                    ></i>
                  </div>
                </th>
              ))}
              <th>
                View Bets
                {parentId && parentId != "null" && currentUser != parentId && (
                  <Button
                    variant="subtle-dark"
                    className="btn-sm"
                    onClick={() => handleUserDetail(parentId)}
                  >
                    Back
                  </Button>
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((item, index) => (
              <tr key={item.user_id}>
                <td>{index < 9 ? `0${index + 1}` : index + 1}</td>
                <td
                  className="text-info cursor-pointer"
                  onClick={() => {
                    item?.user_type_id != "1" &&
                      handleUserDetail(item?.user_id);
                  }}
                >
                  <span className="badge bg-info-subtle text-info">
                    {item?.user_type_id != "1" ? "A" : "C"}
                  </span>{" "}
                  {item?.user_name} A/C
                </td>
                {sports.map((key) => (
                  <td key={key.lower_name}>
                    <span
                      className={`text-${
                        Number(item[key.lower_name as keyof typeof item]) < 0
                          ? "danger"
                          : "secondary"
                      }`}
                    >
                      {Number(
                        item[key.lower_name as keyof typeof item]
                      ).toFixed(2)}
                    </span>
                  </td>
                ))}
                <td>
                  <Button
                    variant="subtle-success"
                    className="btn-sm ms-2"
                    onClick={() => {
                      toggleList(item?.user_name);
                      getUserAgent(item?.user_id);
                    }}
                  >
                    P
                  </Button>
                  {item?.user_type_id != "1" ? null : (
                    <Button
                      variant="subtle-dark"
                      className="btn-sm"
                      onClick={() =>
                        isEvent
                          ? navigate(
                              `/view-bets/${matchid}/${marketid}/${type}/${
                                item?.user_id
                              }/${isCasino || "1"}`
                            )
                          : navigate(`/reports/Statement/${item?.user_id}`)
                      }
                    >
                      {isEvent ? "View Bets" : "Statement"}
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th colSpan={1}></th>
              <th>Total</th>
              {sports.map((key) => (
                <th className={`text-${key?.total < 0 ? "danger" : "secondary"}`}>
                  {key?.total.toFixed(2)}
                </th>
              ))}

              <th colSpan={1}></th>
            </tr>
          </tfoot>
        </Table>
      </div>

      <Modal
        show={showList}
        onHide={() => toggleList()}
        className="zoomIn"
        scrollable
      >
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <div className="d-flex align-items-center flex-wrap">
            Parent List of{" "}
            <span className="text-muted fs-xs fw-normal mx-0 mx-sm-2 fs-xs">({selectedUser})</span>
          </div>
          <Button variant="light btn-sm" onClick={() => toggleList()}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          {agentList?.map((item) => {
            return (
              <p>{item.name}({item.user_name})</p>
            );
          })}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SportsWisePLTable;
