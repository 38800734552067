import { apiHandler } from "../apiService";
import { settingResourcs as settingResourcs } from "./resources";

export const settingServices = {
    getWebisteData: async () => {
        const params = {
            resource: settingResourcs.GET_ALL_WEBSITE,
        };
        return await apiHandler(params);
    },
    getCheckWebsiteName: async (data: checkWebsite) => {
        const params = {
            resource: settingResourcs.CHECK_WEBSITE,
            data
        };
        return await apiHandler(params);
    },
    getAloowUNmatedBet: async (data: unmatchedBetPayload) => {
        const params = {
            resource: settingResourcs.ALLOW_UNMATCHEDBET,
            data
        };
        return await apiHandler(params);
    },
    updateWebsiteSetting: async (userId: string, data: updateWebsitePayload) => {
        const resource = settingResourcs.UPDATE_WEBSITE;
        const params = {
            resource: {
                ...resource,
                URL: `${resource.URL}/${userId}`,
            },
            data,
        };
        return await apiHandler(params);
    },
    getDeletedDomain: async (userId: string, data: DeletedWebsiteData) => {
        const resource = settingResourcs.DELETE_WEBSITE;
        const params = {
            resource: {
                ...resource,
                URL: `${resource.URL}/${userId}`,
            },
            data,
        };
        return await apiHandler(params);
    },
    updateLogo: async (title: string, slug: string, data: any) => {
        const resource = settingResourcs.UPLOAD_LOGO;
        const params = {
            resource: {
                ...resource,
                URL: `${resource.URL}?title=${title}&slug=${slug}`,
            },
            data,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        return await apiHandler(params);
    },


    getSocilIcon: async (data: socilaIconPayload) => {
        const params = {
            resource: settingResourcs.GET_SOCIAL_ICON,
            data
        };
        return await apiHandler(params);
    },
    createSocialMediaIcon: async (data: CreateIconPayload) => {
        const params = {
            resource: settingResourcs.CREATE_SOCILA_ICON,
            data
        };
        return await apiHandler(params);
    },
    getUpdateTV: async (data: updateTvPayload) => {
        const params = {
            resource: settingResourcs.UPDATE_WEBSITE_TVURL,
            data
        };
        return await apiHandler(params);
    },
    getDomainCount: async (data: DomainCountPayload) => {
        const params = {
            resource: settingResourcs.DOMAIN_COUNT,
            data
        };
        return await apiHandler(params);
    },
    updateDomainNewOld: async (data: updateDomainPayload) => {
        const params = {
            resource: settingResourcs.UPDATE_DOMAIN_NEW_OLD,
            data
        };
        return await apiHandler(params);
    },
    getContent: async (popuse: string, domainName?: string) => {
        const resource = settingResourcs.CONTENT_GET;
        const params = {
            resource: {
                ...resource,
                URL: `${resource.URL}?content_type=${popuse}&host=${domainName}`,
            },
        };
        return await apiHandler(params);
    },
    updateUpUp: async (data: any) => {
        const params = {
            resource: settingResourcs.UPLOAD_POPUP,
            data,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        return await apiHandler(params);
    },
    updateCasinoRate: async (data: updateCasinoRate) => {
        const params = {
            resource: settingResourcs.UPDATE_CASINO_RATE,
            data
        };
        return await apiHandler(params);
    },
    createWebsite: async (data: createWebsitePayload) => {
        const params = {
            resource: settingResourcs.CREATE_WEBSITE,
            data
        };
        return await apiHandler(params);
    },
    checkSiteData: async (data: checkSiteDataPayload) => {
        const params = {
            resource: settingResourcs.CHECK_SITE_DATA,
            data
        };
        return await apiHandler(params);
    },
    getPendingMarket: async () => {
        const params = {
            resource: settingResourcs.PENDING_MARKET,
        };
        return await apiHandler(params);
    },
    getMarketResult: async (data:ResultMarketPayload) => {
        const params = {
            resource: settingResourcs.MARKET_RESULT,
            data
        };
        return await apiHandler(params);
    },
    getEventList: async (data:eventListPayload) => {
        const params = {
            resource: settingResourcs.EVENT_LIST,
            data
        };
        return await apiHandler(params);
    },
    setOddsResult: async (data:oddsRsultPayload) => {
        const params = {
            resource: settingResourcs.ODDS_RESULT,
            data
        };
        return await apiHandler(params);
    },
    getOddsABD: async (data:oddsAbdPayload) => {
        const params = {
            resource: settingResourcs.ODDS_ABD,
            data
        };
        return await apiHandler(params);
    },
    getFancyResult: async (data:fancyResultPayload) => {
        const params = {
            resource: settingResourcs.FANCY_RESULT,
            data
        };
        return await apiHandler(params);
    },
    getSessionResult: async (data:sessionResultPayload) => {
        const params = {
            resource: settingResourcs.SESSION_RESULT,
            data
        };
        return await apiHandler(params);
    },
    getSessionAbd: async (data:sessionAbdtPayload) => {
        const params = {
            resource: settingResourcs.SESSION_ABD,
            data
        };
        return await apiHandler(params);
    },
    getFancyPosition: async (data:sessionAbdtPayload) => {
        const params = {
            resource: settingResourcs.FANCY_POSITION,
            data
        };
        return await apiHandler(params);
    },
    getResult: async (data:oddsAbdPayload) => {
        const params = {
            resource: settingResourcs.GET_RESULT,
            data
        };
        return await apiHandler(params);
    },
    getLotusBets: async (data:lotusBetsPayload) => {
        const params = {
            resource: settingResourcs.LOTUS_BETS,
            data
        };
        return await apiHandler(params);
    },
    getUserName: async (data:userNamePayload) => {
        const params = {
            resource: settingResourcs.USER_NAME,
            data
        };
        return await apiHandler(params);
    },
    getRoundStatus: async (data:roundStatus) => {
        const params = {
            resource: settingResourcs.GET_ROUND_STATUS,
            data
        };
        return await apiHandler(params);
    },
    getShowAgent: async (data:showAgentPayload) => {
        const params = {
            resource: settingResourcs.SHOW_AGENT,
            data
        };
        return await apiHandler(params);
    },
    getManualResult: async (data:manualPayload) => {
        const params = {
            resource: settingResourcs.MANUAL_RESULT_DECLARE,
            data
        };
        return await apiHandler(params);
    },
    getVoidResult: async (data:resultPayload) => {
        const params = {
            resource: settingResourcs.VOID_RESULT_DECLARE,
            data
        };
        return await apiHandler(params);
    },
    getAccessToken: async () => {
        const params = {
            resource: settingResourcs.GET_ACCESS_TOKEN,            
        };
        return await apiHandler(params);
    },
    generateAccessToken: async() => {
        const params = {
            resource: settingResourcs.GENERATE_ACCESS_TOKEN,            
        };
        return await apiHandler(params);
    },
    getRoundList: async() => {
        const params = {
            resource: settingResourcs.GET_ROUND_LIST,            
        };
        return await apiHandler(params);
    },
    getMatchRoolBack: async(data:matchRollbackPaylod) => {
        const params = {
            resource: settingResourcs.MATCH_ROLLBACK, 
            data           
        };
        return await apiHandler(params);
    },
    getOddsRollBack: async(data:oddsRollPayload) => {
        const params = {
            resource: settingResourcs.ODDS_ROLLBACK, 
            data           
        };
        return await apiHandler(params);
    },
    getSessionRollback: async(data:sessionRollbackPaylod) => {
        const params = {
            resource: settingResourcs.SESSION_ROLLBACK, 
            data           
        };
        return await apiHandler(params);
    },
    getUserMobileName: async(data:userMobilePayload) => {
        const params = {
            resource: settingResourcs.USER_MOBILE_NAME, 
            data           
        };
        return await apiHandler(params);
    },
    getCheckUserName: async(data:checkUserNamePayload) => {
        const params = {
            resource: settingResourcs.CHECK_USER_NAME, 
            data           
        };
        return await apiHandler(params);
    },

    getDeleted: async (data: any) => {
        const resource = settingResourcs.DELETED_CONTENT;
        const params = {
            resource,
            // resource: {
            //     ...resource,
            //     URL: `${resource.URL}/${id}`,
            // },
            data,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };
        return await apiHandler(params);
    },
    getUploadContentAgent: async (title: string,slug:string, category:boolean | string, data:any ) => {
        const resource = settingResourcs.UPLOAD_CONTENT_AGENT;
        const params = {
            resource: {
                ...resource,
                URL: `${resource.URL}?title=${title}&slug=${slug}&category=${category}`,
            },
            data,
            headers: {
                'Content-Type': 'multipart/form-data',
            }, 
        };
        return await apiHandler(params);
    },

    getUploadContent: async (data: any) => {
        const params = {
            resource: settingResourcs.UPLOAD_CONTENT, 
            data,
            headers: {
                'Content-Type': 'multipart/form-data',
            },      
        };
        return await apiHandler(params);
    },


    getUpdateExposure: async(data:expPayload) => {
        const params = {
            resource: settingResourcs.UPDATE_EXPOSURE, 
            data           
        };
        return await apiHandler(params);
    },
    getEventAnylysis: async() => {
        const params = {
            resource: settingResourcs.EVENT_ANALYSIS,          
        };
        return await apiHandler(params);
    },
    getUserbyUserName: async(data:userNameSearch) => {
        const params = {
            resource: settingResourcs.GET_USER_BY_USERNAME,   
            data       
        };
        return await apiHandler(params);
    },
    getLaunchUrl: async(data:launchRes) => {
        const params = {
            resource: settingResourcs.LAUNCH_URL,   
            data       
        };
        return await apiHandler(params);
    },
    getClearExp: async(data:clearExpReq) => {
        const params = {
            resource: settingResourcs.CLEAR_EXP,   
            data       
        };
        return await apiHandler(params);
    },
    getManualResultDeclare: async(data:clearExpReq) => {
        const params = {
            resource: settingResourcs.MANUAL_RESULT_DEC,   
            data       
        };
        return await apiHandler(params);
    },
    getCreditReferenceLogs: async(data:showAgentPayload) => {
        const params = {
            resource: settingResourcs.CREADIT_REFERENCE_LOGS,   
            data       
        };
        return await apiHandler(params);
    },
    getPasswordChangedHistory: async(data:chnagePassReq) => {
        const params = {
            resource: settingResourcs.GET_PASSWORD_CHANGE_HISTORY,   
            data       
        };
        return await apiHandler(params);
    },
    getListBanner: async() => {
        const params = {
            resource: settingResourcs.LIST_BANNER,        
        };
        return await apiHandler(params);
    },
    getTotalReport: async(data:TotalReportPayload) => {
        const params = {
            resource: settingResourcs.GET_TOTAL_REPORT,
            data        
        };
        return await apiHandler(params);
    },
    getSportByPl: async(data:sportWisePlPayload) => {
        const params = {
            resource: settingResourcs.SPORT_WISE_PL,
            data        
        };
        return await apiHandler(params);
    },

}