import BreadCrumb from "Common/BreadCrumb";
import { Container, Card } from "react-bootstrap";
import { useEffect, useState } from "react";
import { authServices } from "Utils/auth/services";
import MarketTable from "Common/Tables/MarketTable";

const MyMarket = () => {
  const [markets, setMarkets] = useState<MarketData[]>([]);

  const userId = localStorage.getItem("userId");

  const geOpenBetsList = async () => {
    const { response } = await authServices.myMarket({
      user_id: userId || '',
    });
    if (response.data) {
      setMarkets(response.data);
    }
  };

  useEffect(() => {
    geOpenBetsList();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="page-content">
      <Container fluid>
        <div
          className="position-relative mb-2 d-flex justify-content-between align-items-center"
          style={{ minHeight: "43px" }}
        >
          <BreadCrumb title={"My Market"} pageTitle="Dashboard" back />
        </div>
        <>
          <Card>
            <Card.Body>
              <MarketTable
                marketData={markets}
              />
            </Card.Body>
          </Card>
        </>
      </Container>
    </div>
  );
};

export default MyMarket;
