import { ListPagination } from "Common/ListPagination";
import TableEntry from "Common/TableEntry";
import moment from "moment";
import { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface ProfitLossTableProps {
  profitLossData: ProfitLossData[];
  page: number;
  limit: number;
  listTotal: ProfitLossTotal;
  profitLossSum: ProfitLossSum;
  handlePageFilter: (value: number, limit: number) => void;
  startDate: string | Date;
  endDate: string | Date;
  showResult: boolean
}

const ProfitLossTable = ({
  profitLossData,
  page,
  limit,
  listTotal,
  profitLossSum,
  handlePageFilter,
  startDate,
  endDate,
  showResult
}: ProfitLossTableProps) => {
  const navigate = useNavigate();
  const [showList, setList] = useState(false);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "asc" | "desc";
  } | null>(null);

  const toggleList = () => {
    setList(!showList);
  };

  const handleSort = (key: string) => {
    setSortConfig((prev) => {
      if (prev && prev.key === key && prev.direction === "asc") {
        return { key, direction: "desc" };
      }
      return { key, direction: "asc" };
    });
  };

  const sortedData = [...profitLossData].sort((a, b) => {
    if (!sortConfig) return 0;

    const aValue = a[sortConfig.key as keyof typeof a];
    const bValue = b[sortConfig.key as keyof typeof b];

    // Check if the values are numbers or strings
    const isNumeric = !isNaN(Number(aValue)) && !isNaN(Number(bValue));

    if (isNumeric) {
      // Numeric comparison
      if (sortConfig.direction === "asc") {
        return Number(aValue) - Number(bValue);
      } else {
        return Number(bValue) - Number(aValue);
      }
    } else {
      // String comparison (case-insensitive)
      const aString = String(aValue).toLowerCase();
      const bString = String(bValue).toLowerCase();
      if (sortConfig.direction === "asc") {
        return aString.localeCompare(bString);
      } else {
        return bString.localeCompare(aString);
      }
    }
  });

  return (
    <>
      <div className="table-responsive">
        <Table className="table-centered align-middle table-nowrap table-striped">
          <thead className="table-light">
            <tr>
              <th>
                <div className="d-flex">
                  No.{" "}
                  <i
                    className={`ms-1 ri-arrow-up-down-line ${sortConfig?.key != "index" && "text-muted"
                      }  fs-md align-middle cursor-pointer select-none  ${sortConfig?.key === "index" ? "sticky-active" : "sticky"
                      }`}
                    onClick={() => handleSort("index")}
                  ></i>
                </div>
              </th>
              <th>
                <div className="d-flex">
                  Date{" "}
                  <i
                    className={`ms-1 ri-arrow-up-down-line ${sortConfig?.key != "result_date" && "text-muted"
                      }  fs-md align-middle cursor-pointer select-none  ${sortConfig?.key === "result_date"
                        ? "sticky-active"
                        : "sticky"
                      }`}
                    onClick={() => handleSort("result_date")}
                  ></i>
                </div>
              </th>
              <th>Sports | Series</th>
              <th>Market | Match</th>
              <th>
                <div className="d-flex">
                  P | L{" "}
                  <i
                    className={`ms-1 ri-arrow-up-down-line ${sortConfig?.key != "p_l" && "text-muted"
                      }  fs-md align-middle cursor-pointer select-none  ${sortConfig?.key === "p_l" ? "sticky-active" : "sticky"
                      }`}
                    onClick={() => handleSort("p_l")}
                  ></i>
                </div>
              </th>
              <th>
                <div className="d-flex">
                  Commission{" "}
                  <i
                    className={`ms-1 ri-arrow-up-down-line ${sortConfig?.key != "commission" && "text-muted"
                      }  fs-md align-middle cursor-pointer select-none  ${sortConfig?.key === "commission"
                        ? "sticky-active"
                        : "sticky"
                      }`}
                    onClick={() => handleSort("commission")}
                  ></i>
                </div>
              </th>
              <th>
                <div className="d-flex">
                  Net PNL{" "}
                  <i
                    className={`ms-1 ri-arrow-up-down-line ${sortConfig?.key != "net_pl" && "text-muted"
                      }  fs-md align-middle cursor-pointer select-none  ${sortConfig?.key === "net_pl" ? "sticky-active" : "sticky"
                      }`}
                    onClick={() => handleSort("net_pl")}
                  ></i>
                </div>
              </th>
              {
                showResult && <th>Result</th>
              }

              <th>View Bets</th>
            </tr>
          </thead>
          <tbody>
            {sortedData?.map((item, index) => {
              return (
                <tr>
                  <td>{(page - 1) * limit + index + 1}</td>
                  <td>
                    <p className="mb-0">
                      {moment(item?.result_date).format("DD-MM-YY")}
                    </p>
                    <p className="mb-0">
                      {moment(item?.result_date).format(" hh:mm:ss:SSS A")}
                    </p>
                  </td>
                  <td>
                    <strong>{item?.sport_name}</strong>
                    <p className="mb-0 text-muted">{item?.series_name}</p>
                  </td>
                  <td>
                    <strong>{item?.event_name}</strong>
                    <p className="mb-0 text-muted">{item?.match_name}</p>
                  </td>
                  <td
                    className={`text-${item?.p_l < 0 ? "danger" : "secondary"}`}
                  >
                    {item?.p_l?.toFixed(2)}
                  </td>
                  <td
                    className={`text-${item?.commission < 0 ? "danger" : "secondary"
                      }`}
                  >
                    {item?.commission?.toFixed(2)}
                  </td>
                  <td
                    className={`text-${item?.net_pl < 0 ? "danger" : "secondary"
                      }`}
                  >
                    {item?.net_pl?.toFixed(2)}
                  </td>
                  {
                    showResult && <td>{item?.winner_name}</td>
                  }

                  <td>
                    <Button
                      variant="subtle-dark"
                      className="btn-sm"
                      onClick={() =>
                        navigate(
                          `/view-bets/${item.match_id}/${item.event_id}/${item.type
                          }/null/${item?.sport_id === "-100" ? 2 : 1}`
                        )
                      }
                    >
                      View Bets
                    </Button>
                    <Button
                      variant="subtle-primary"
                      className="btn-sm ms-2"
                      onClick={() =>
                        navigate(
                          `/sports-wise-pl/${item.match_id}/${item.event_id}/${item.type
                          }/${item?.sport_id === "-100" ? 2 : 1
                          }/${startDate}/${endDate}`
                        )
                      }
                    >
                      P | L
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <th colSpan={3}></th>
              <th>Total</th>
              <th
                className={`text-${profitLossSum?.p_l < 0 ? "danger" : "secondary"
                  }`}
              >
                {profitLossSum?.p_l.toFixed(2) || 0}
              </th>
              <th
                className={`text-${profitLossSum?.commission < 0 ? "danger" : "secondary"
                  }`}
              >
                {profitLossSum?.commission.toFixed(2) || 0}
              </th>
              <th
                colSpan={3}
                className={`text-${profitLossSum?.net_pl < 0 ? "danger" : "secondary"
                  }`}
              >
                {profitLossSum?.net_pl.toFixed(2) || 0}
              </th>
            </tr>
          </tfoot>
        </Table>
      </div>
      <nav
        aria-label="Page navigation"
        className="px-3 d-flex flex-column flex-sm-row align-items-center"
      >
        <TableEntry
          limit={limit}
          handlelimitFilter={(value: number) => handlePageFilter(page, value)}
        />
        <ListPagination
          listTotal={listTotal}
          handlePageFilter={(value: number) => handlePageFilter(value, limit)}
        />
      </nav>

      <Modal show={showList} onHide={toggleList} className="zoomIn" scrollable>
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <div className="d-flex align-items-center flex-wrap">
            Parent List of{" "}
            <span className="text-muted fs-xs fw-normal mx-0 mx-sm-2 fs-xs">(lcbook247-prime)</span>
          </div>
          <Button variant="light btn-sm" onClick={toggleList}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>List</Modal.Body>
      </Modal>
    </>
  );
};

export default ProfitLossTable;
