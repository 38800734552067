
import { Badge, Button, Card, Col, Dropdown, Modal, Table } from "react-bootstrap";
import { useState } from "react";

interface Props {
    fetchData: (type: string, days: number, query: string[]) => Promise<void>,
    data: any,


}

const TotalBetsCountWithPL = ({ fetchData, data }: Props) => {
    const [showModal, setModal] = useState(false)
    const [listData, setListData] = useState<any>()
    const ToggleModal = (dataList: any) => {
        setModal(!showModal);
        setListData(dataList);
    }
    const [selectName, setSelectName] = useState("Select")

    const handleDate = (days: number, name: string) => {
        setSelectName(name);
        fetchData("betsCountPlQuery", days, ["betsCountPlQuery"])
    }
    const clickHandler = () => {
        setModal(!showModal)
    }

    return (
        <>
            <Col xxl={6}>
                <Card className="card-height-100">
                    <Card.Header className="d-flex border-bottom pb-3">
                        <h5 className="card-title mb-0 flex-grow-1">Total Bets Count With PL
                            <Badge className="cursor-pointer bg-body-secondary border border-primary text-primary ms-1"
                                onClick={() => ToggleModal(data?.betsCountPlQuery?.BetsCountWithPL)}>View All</Badge></h5>
                        <div className="flex-shrink-0">
                            <Dropdown className="card-header-dropdown sortble-dropdown cursor-pointer">
                                <Dropdown.Toggle as='a' className="text-reset arrow-none mb-0">
                                    <span className="text-muted dropdown-title">{selectName}</span> <i className="mdi mdi-chevron-down ms-1"></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="dropdown-menu-end">
                                    <Dropdown.Item onClick={() => handleDate(1, "Today")}>Today</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleDate(7, "This Week")}>This Week</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleDate(30, "This Month")}>This Month</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Card.Header>
                    <div className="card-body">
                        <div className="table-resposive">
                            <Table>
                                <thead className="table-light">
                                    <tr>
                                        <th>Date</th>
                                        <th>Totle Bets</th>
                                        <th>Totle Volume</th>
                                        <th>P/L</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.betsCountPlQuery?.BetsCountWithPL?.length !== 0 ? data?.betsCountPlQuery?.BetsCountWithPL?.slice(0, 10)?.map((items: any) => {
                                        return (
                                            <tr>
                                                <td>{items?.date}</td>
                                                <td>{items?.total_bets}</td>
                                                <td>{items?.total_volume}</td>
                                                <td className={items?.total_pl < 0 ? "text-danger" : ""}>{items?.total_pl?.toFixed(2)}</td>
                                            </tr>
                                        )
                                    }) : <tr>
                                        <td colSpan={4} className="text-center">No Data Found!</td>
                                    </tr>}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Card>
            </Col>

            <Modal show={showModal} onHide={clickHandler} className="zoomIn" scrollable>
                <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                    <span>Total Bets Count With PL</span>
                    <Button variant="light btn-sm" onClick={clickHandler}>
                        <i className="ri-close-line fs-xl align-middle"></i>
                    </Button>
                </Modal.Header>
                <Modal.Body className='fs-md'>
                    <div className="table-resposive">
                        <Table>
                            <thead className="table-light">
                                <tr>
                                    <th>Date</th>
                                    <th>Totle Bets</th>
                                    <th>Totle Volume</th>
                                    <th>P/L</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    listData?.map((items: any) => {
                                        return (
                                            <tr>
                                                <td>{items?.date}</td>
                                                <td>{items?.total_bets}</td>
                                                <td>{items?.total_volume}</td>
                                                <td className={items?.total_pl < 0 ? "text-danger" : ""}>{items?.total_pl?.toFixed(2)}</td>
                                            </tr>
                                        )
                                    })
                                }


                            </tbody>
                        </Table>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default TotalBetsCountWithPL;